import {ForwardedRef, forwardRef} from "react";
import {CheckboxPropsType} from "./index.types";
import s from './index.module.scss';
import classNames from "classnames/bind";

const cx = classNames.bind(s);

export const Checkbox = forwardRef<ForwardedRef<HTMLInputElement>, CheckboxPropsType>(
  ({label, classNameComponent, classNameLabel, ...props}, ref) => {
    return (
      <label className={cx('Component', classNameComponent || '')}>
        <input
          ref={ref as any}
          type={'checkbox'}
          {...props}
        />
        <span className={cx('Checkbox')}/>
        {label ? <p className={cx('Label', classNameLabel)}>{label}</p> : ''}
      </label>
    )
  })