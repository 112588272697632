import {useGoogleLogin as useGoogleLoginLib, useGoogleOneTapLogin} from '@react-oauth/google';
import {toast} from "react-toastify";
import jwtDecode from "jwt-decode";
import {useGoogleAuthMutation, UserType} from "../../apollo/types_schema";
import {ModalTypeEnum} from "../../store/reducers/base";
import {useModalSign} from "./useModalSign";
import {useSignIn} from "../sign";

export interface ResponseUserInfo {
  sub: string;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  email: string;
  email_verified: boolean;
  locale: string;
}


export function useGoogleOneTap() {
  const signIn = useSignIn()
  const {setTypeModalSign, openModalSign} = useModalSign()
  const [googleAuthMutation] = useGoogleAuthMutation()
  useGoogleOneTapLogin({
      onSuccess: async credentialResponse => {
        if (credentialResponse.credential != null) {
          const userCredential = jwtDecode(credentialResponse?.credential) as ResponseUserInfo
          const result = await googleAuthMutation({
            variables: {
              email: userCredential?.email,
              name: userCredential?.name ?? ''
            }
          })
          if (result?.data?.googleAuth?.token) {
            if (!result?.data?.googleAuth?.isExistUser) {
              setTypeModalSign(ModalTypeEnum.UserEdit)
              openModalSign()
            }
            signIn(result?.data?.googleAuth?.token, result?.data?.googleAuth?.user as UserType)
          }
        }
      },
    }
  )

}

export function useGoogleLogin(callback: (response: ResponseUserInfo) => void) {
  const login = useGoogleLoginLib({
    onSuccess: async tokenResponse => {
      try {
        const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            'Authorization': `Bearer ${tokenResponse.access_token}`
          }
        }).then(res => res.json())
        callback(response as ResponseUserInfo)
      } catch (e) {
        toast('Google login error', {type: 'error'})
      }
    }
  });

  return {
    handleGoogle: login
  }
}