import {ApolloClient, useApolloClient} from '@apollo/client'
import {Dispatch} from 'redux'
import {useDispatch} from 'react-redux'
import {editBase} from '../store/reducers/base'
import {useStore} from './hooks';
import {useGetCountersQuery, useMeQuery, UserType} from 'apollo/types_schema';
import {getMaxAttempts} from "../variables";
import {googleLogout} from '@react-oauth/google';
import {useSearchParams} from "react-router-dom";

export const JWT_TOKEN_KEY = 'JWT_TOKEN'
export const IS_AUTH = 'IS_AUTH'

// JWT token, that we use in each request to API
export const getJwtToken = () => window.localStorage.getItem(JWT_TOKEN_KEY)

export const useSignIn = () => {
  const dispatch = useDispatch()
  const apollo = useApolloClient()
  const [search] = useSearchParams()

  return (token: string, user: UserType) => signIn(token, user, dispatch, apollo, search)
}

export const useSignOut = () => {
  const apollo = useApolloClient()
  const dispatch = useDispatch()
  return () => signOut(dispatch, apollo, googleLogout)
}

export const signOut = (dispatch: Dispatch, apollo: ApolloClient<any>, googleLogout: any) => {
  googleLogout?.()
  window.localStorage.clear()
  apollo.cache.reset()
  dispatch(editBase([{field: 'isAuth', value: false}, {field: 'user', value: null}]))
}

export const signIn = (token: string, user: UserType, dispatch: Dispatch, apollo: ApolloClient<any>, search: any) => {

  window.localStorage.setItem(JWT_TOKEN_KEY, token)
  window.localStorage.setItem(IS_AUTH, JSON.stringify(true))
  dispatch(editBase([{field: 'isAuth', value: true}, {field: 'user', value: user}]))
  dispatch(editBase({field: 'isOpenModalSign', value: false}))
  apollo.reFetchObservableQueries()


}
export const useMe = () => {
  const dispatch = useDispatch();
  const signOut = useSignOut()
  const isAuth = useStore(state => state.base.isAuth)
  useGetCountersQuery({
    onCompleted: (data) => {
      !isAuth && data?.getCounters?.globalCounter && getMaxAttempts(data?.getCounters?.globalCounter)
      isAuth && data?.getCounters?.premiumCounter && getMaxAttempts(data?.getCounters?.premiumCounter)
    }
  })
  useMeQuery({
    skip: !isAuth,
    onError() {
      isAuth && signOut()
    },
    onCompleted: (data) => {
      data.me && dispatch(editBase({field: 'user', value: data.me}))
    }
  })
}