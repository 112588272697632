import {useState} from "react";

export function useModal<T>(initialState = false, initialPayload: T | null = null) {
  const [isOpen, setIsOpen] = useState(initialState)
  const [payload, setPayload] = useState<T | null>(initialPayload)

  const open = (payload: T) => {
    setIsOpen(true)
    setPayload(payload ?? null)
  }

  const close = () => {
    setIsOpen(false)
    setPayload(null)
  }
  const changePayload = (payload: T) => {
    setPayload(payload)
  }
  return {isOpen, open, close, payload, changePayload}
}
