import App from "./App";
import {Provider as ReduxProvider} from "react-redux";
import {store} from "../store/store";
import {ApolloProvider} from "@apollo/client";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import React from "react";
import client from "../apollo";
import {BrowserRouter} from "react-router-dom";
import {CookiesProvider} from 'react-cookie';
import {isDev} from "../variables";
import {GoogleOAuthProvider} from '@react-oauth/google';


export const clientId = isDev ? '630912894959-dcu6lc9aqmp67mg2ttuh2o74qdacu3as.apps.googleusercontent.com' : '626191402967-knvqen897jjejgh1rnk3j1dbkm1kt3pg.apps.googleusercontent.com'


export const AppProvider = () => {
  return (
    <BrowserRouter>
      <CookiesProvider>
        <ApolloProvider client={client}>
          <ReduxProvider store={store}>
            <GoogleOAuthProvider clientId={clientId}>
              <App/>
            </GoogleOAuthProvider>
            <ToastContainer/>
          </ReduxProvider>
        </ApolloProvider>
      </CookiesProvider>
    </BrowserRouter>

  )
}