import {lazy} from "react";

const Home = lazy(() => /** WebpackName: HomePage*/ import('./Home'));
const ForgotPassword = lazy(() => /** WebpackName: ForgotPasswordPage*/ import('./ForgotPassword'));
const Feature = lazy(() => /** WebpackName: FeaturePage*/ import('./Feature'));
const Features = lazy(() => /** WebpackName: FeaturesPage*/ import('./Features'));
const Pricing = lazy(() => /** WebpackName: PricingPage*/ import('./Pricing'));
const Platform = lazy(() => /** WebpackName: PlatformPage*/ import('./Platform'));
const Membership = lazy(() => /** WebpackName: MembershipPage*/ import('./Membership'));
const OurMembers = lazy(() => /** WebpackName: OurMembersPage*/ import('./OurMembers'));
const Subscription = lazy(() => /** WebpackName: SubscriptionPage*/ import('./Subscription'));
export {
  Features,
  Home,
  OurMembers,
  Platform,
  Pricing,
  Feature,
  ForgotPassword,
  Membership,
  Subscription
}