import React, {useEffect} from "react";
import s from "./index.module.scss";
import classNames from "classnames/bind";
import {PaymentSuccess} from "components/UI/PaymentSuccess";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Loading} from "components/modules";
import {RedirectPayment} from "helpers/types";
import {RoutePath} from "../../helpers/routes";
import {useDispatch} from "react-redux";
import {editBase} from "../../store/reducers/base";

const cx = classNames.bind(s);

const PaymentRedirect = (): any => {
  const [search] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isSuccess = search.get('status') === RedirectPayment.Success || search.get('status') === 'true'
  useEffect(() => {
    debugger
    if (!isSuccess) {
      navigate(RoutePath.Membership, {
        state: RedirectPayment.Failed
      })
    } else if (isSuccess) {
      dispatch(editBase({field: 'paymentSuccess', value: true}))
    }
  }, [isSuccess])
  return isSuccess ? <PaymentSuccess/> : <Loading/>
};

export default PaymentRedirect;
