import React, {FC, useEffect, useState} from 'react';
import classNames from 'classnames/bind'
import s from './index.module.scss'
import {ModalProps} from "components/UI/Modal";
import {Checkbox, Modal, Typography} from 'components/modules';
import {ModalFeatureEnum, ModalFeaturePayloadType} from "pages/Features";
import {FontWeight, TypographyVariant} from "helpers/types";

const cx = classNames.bind(s)
export const LOCAL_STORAGE_isHideFollow = 'isHideFollow'
export const LOCAL_STORAGE_isHideSave = 'isHideSave'
type ModalFollowPropsType = ModalProps & { payload: ModalFeaturePayloadType }
const ModalFollow: FC<ModalFollowPropsType> = ({payload, ...propsModal}) => {
  const isSaveView = payload?.view === ModalFeatureEnum.Save
  const [isChecked, setChecked] = useState(false)
  useEffect(() => {
    setChecked(false)
  }, [payload])
  return (
    <Modal
      className={cx('WrapModal')}
      {...propsModal}>
      <div className={cx('Component')}>
        <svg width="43" height="33" viewBox="0 0 43 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.4997 26.1748L4.74965 17.279C4.52085 17.0435 4.24834 16.8566 3.94794 16.729C3.64754 16.6014 3.32522 16.5357 2.99965 16.5357C2.67409 16.5357 2.35177 16.6014 2.05136 16.729C1.75096 16.8566 1.47846 17.0435 1.24965 17.279C1.01805 17.5116 0.834175 17.7887 0.708676 18.0941C0.583177 18.3995 0.518555 18.7272 0.518555 19.0582C0.518555 19.3891 0.583177 19.7168 0.708676 20.0223C0.834175 20.3277 1.01805 20.6047 1.24965 20.8373L11.7247 31.4869C12.6997 32.4782 14.2747 32.4782 15.2497 31.4869L41.7497 4.57066C41.9813 4.33804 42.1651 4.06099 42.2906 3.75559C42.4161 3.45018 42.4808 3.12248 42.4808 2.79149C42.4808 2.4605 42.4161 2.13281 42.2906 1.8274C42.1651 1.52199 41.9813 1.24495 41.7497 1.01233C41.5208 0.776867 41.2483 0.589923 40.9479 0.462333C40.6475 0.334742 40.3252 0.269043 39.9997 0.269043C39.6741 0.269043 39.3518 0.334742 39.0514 0.462333C38.751 0.589923 38.4785 0.776867 38.2497 1.01233L13.4997 26.1748Z"
            fill="#FF994F"/>
        </svg>
        <Typography
          className={cx('Title')}
          variant={TypographyVariant.h20}
          weight={FontWeight.SemiBold}>
          {isSaveView
            ? 'Your feature is saved and you can find it in the saved section.'
            : `You have subscribed to the “${payload?.tagName || ''}” tag. `}
        </Typography>
        {!isSaveView && <Typography className={cx('Description')}>
          You will be notified when new features are released for this tag.
        </Typography>}
        <Checkbox
          classNameComponent={cx('Checkbox')}
          label={'Don\'t show this notification again'}
          checked={isChecked}
          onChange={({target: {checked}}) => {
            if (checked) {
              window.localStorage.setItem(isSaveView ? LOCAL_STORAGE_isHideSave : LOCAL_STORAGE_isHideFollow, JSON.stringify(true))
            } else {
              window.localStorage.removeItem(isSaveView ? LOCAL_STORAGE_isHideSave : LOCAL_STORAGE_isHideFollow)
            }
            setChecked(checked)
          }}

        />
      </div>
    </Modal>
  );
};

export default ModalFollow