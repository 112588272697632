import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: any;
  GenericScalar: any;
};

export type CancelOrder = {
  __typename?: 'CancelOrder';
  errors?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  order?: Maybe<OrderType>;
};

export type CreateOrder = {
  __typename?: 'CreateOrder';
  checkoutUrl?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  order?: Maybe<OrderType>;
};

export enum CurrencyEnum {
  Usd = 'USD'
}

export enum DurationEnum {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type FeatureType = {
  __typename?: 'FeatureType';
  created?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<UserType>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isOnlyMember?: Maybe<Scalars['Boolean']>;
  isSaved?: Maybe<Scalars['Boolean']>;
  previewDescription?: Maybe<Scalars['String']>;
  savedFeature?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<TagType>>>;
  title: Scalars['String'];
};

export type FollowGroup = {
  __typename?: 'FollowGroup';
  errors?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type FollowTag = {
  __typename?: 'FollowTag';
  errors?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type ForgotPassword = {
  __typename?: 'ForgotPassword';
  errors?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type GoogleAuth = {
  __typename?: 'GoogleAuth';
  errors?: Maybe<Scalars['String']>;
  isExistUser?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
};

export type GroupTagType = {
  __typename?: 'GroupTagType';
  group?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<TagType>>>;
};

export type GroupType = {
  __typename?: 'GroupType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ItemType = {
  __typename?: 'ItemType';
  id: Scalars['ID'];
  index: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};

export type ItemsBoughtType = {
  __typename?: 'ItemsBoughtType';
  id: Scalars['ID'];
  index: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};

export enum MessageLocationEnum {
  AnonymousMemberFinishedFeatures = 'ANONYMOUS_MEMBER_FINISHED_FEATURES',
  LastFeatureEnteringFeatureCard = 'LAST_FEATURE_ENTERING_FEATURE_CARD',
  LastFeatureOnTopOfFeatureCard = 'LAST_FEATURE_ON_TOP_OF_FEATURE_CARD',
  MessageOnTopOfFeatureCard = 'MESSAGE_ON_TOP_OF_FEATURE_CARD',
  PartialMemberOnlyFeature = 'PARTIAL_MEMBER_ONLY_FEATURE',
  RegisteredEnteringFeatureCard = 'REGISTERED_ENTERING_FEATURE_CARD',
  UserPaidCongrats = 'USER_PAID_CONGRATS'
}

export type MessageType = {
  __typename?: 'MessageType';
  btnText: Scalars['String'];
  id: Scalars['ID'];
  location?: Maybe<SiteMessagesMessageLocationChoices>;
  /** Variables: $attempts (if you want show number), $username (if you want show name of user) */
  text: Scalars['String'];
  userRole?: Maybe<SiteMessagesMessageUserRoleChoices>;
};

export type Mutations = {
  __typename?: 'Mutations';
  cancelOrder?: Maybe<CancelOrder>;
  createOrder?: Maybe<CreateOrder>;
  followGroup?: Maybe<FollowGroup>;
  followTag?: Maybe<FollowTag>;
  forgotPassword?: Maybe<ForgotPassword>;
  googleAuth?: Maybe<GoogleAuth>;
  refreshToken?: Maybe<Refresh>;
  removeFeature?: Maybe<RemoveFeature>;
  resetForgotPassword?: Maybe<ResetForgotPassword>;
  saveFeature?: Maybe<SaveFeature>;
  signin?: Maybe<Signin>;
  signup?: Maybe<Signup>;
  unfollowGroup?: Maybe<UnFollowGroup>;
  unfollowTag?: Maybe<UnFollowTag>;
  updateOrder?: Maybe<UpdateOrder>;
  updateUserProfile?: Maybe<UpdateUserProfile>;
  verifyToken?: Maybe<Verify>;
};


export type MutationsCancelOrderArgs = {
  orderId: Scalars['ID'];
};


export type MutationsCreateOrderArgs = {
  orderData: OrderInput;
};


export type MutationsFollowGroupArgs = {
  groupId: Scalars['ID'];
};


export type MutationsFollowTagArgs = {
  tagId: Scalars['ID'];
};


export type MutationsForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationsGoogleAuthArgs = {
  email: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationsRefreshTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type MutationsRemoveFeatureArgs = {
  featureId: Scalars['ID'];
};


export type MutationsResetForgotPasswordArgs = {
  newPass: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationsSaveFeatureArgs = {
  featureId: Scalars['ID'];
};


export type MutationsSigninArgs = {
  signin: SigninInput;
};


export type MutationsSignupArgs = {
  signup: SignupInput;
};


export type MutationsUnfollowGroupArgs = {
  groupId: Scalars['ID'];
};


export type MutationsUnfollowTagArgs = {
  tagId: Scalars['ID'];
};


export type MutationsUpdateOrderArgs = {
  orderData: OrderInput;
};


export type MutationsUpdateUserProfileArgs = {
  userData: UpdateUserInput;
};


export type MutationsVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};

export type OrderInput = {
  subscriptionId: Scalars['ID'];
};

export enum OrderStatusEnum {
  Cancel = 'CANCEL',
  Failure = 'FAILURE',
  Paid = 'PAID',
  Pending = 'PENDING',
  Trialing = 'TRIALING'
}

export type OrderType = {
  __typename?: 'OrderType';
  begins?: Maybe<Scalars['DateTime']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isTrialPeriod?: Maybe<Scalars['Boolean']>;
  nextBillingDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatusEnum>;
  subscription?: Maybe<SubscriptionType>;
  trialPeriodEnds?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserType>;
};

export type Query = {
  __typename?: 'Query';
  getCounters?: Maybe<SiteConfigurationType>;
  getFeature?: Maybe<FeatureType>;
  getFeatureFollowing?: Maybe<Array<Maybe<FeatureType>>>;
  getFeatureSaved?: Maybe<Array<Maybe<FeatureType>>>;
  getFeatureTags?: Maybe<Array<Maybe<FeatureType>>>;
  getFeatures?: Maybe<Array<Maybe<FeatureType>>>;
  getMessage?: Maybe<MessageType>;
  getNavbarTags?: Maybe<Array<Maybe<TagType>>>;
  getOrder?: Maybe<OrderType>;
  getOrders?: Maybe<Array<Maybe<OrderType>>>;
  getRelatedFeatures?: Maybe<Array<Maybe<RelatedFeatureType>>>;
  getSubscription?: Maybe<SubscriptionType>;
  getSubscriptions?: Maybe<Array<Maybe<SubscriptionType>>>;
  getSubscriptionsPage?: Maybe<SubscriptionsPageType>;
  getTags?: Maybe<Array<Maybe<GroupTagType>>>;
  me?: Maybe<UserType>;
};


export type QueryGetFeatureArgs = {
  featureId: Scalars['ID'];
};


export type QueryGetFeatureTagsArgs = {
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryGetFeaturesArgs = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryGetMessageArgs = {
  location: MessageLocationEnum;
};


export type QueryGetRelatedFeaturesArgs = {
  featureId: Scalars['ID'];
};


export type QueryGetSubscriptionArgs = {
  subscriptionId: Scalars['ID'];
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type RelatedFeatureType = {
  __typename?: 'RelatedFeatureType';
  created?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isOnlyMember?: Maybe<Scalars['Boolean']>;
  previewDescription?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<TagType>>>;
  title?: Maybe<Scalars['String']>;
};

export type RemoveFeature = {
  __typename?: 'RemoveFeature';
  errors?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type ResetForgotPassword = {
  __typename?: 'ResetForgotPassword';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SaveFeature = {
  __typename?: 'SaveFeature';
  errors?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type Signin = {
  __typename?: 'Signin';
  errors?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
};

export type SigninInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Signup = {
  __typename?: 'Signup';
  errors?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
};

export type SignupInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SiteConfigurationType = {
  __typename?: 'SiteConfigurationType';
  globalCounter: Scalars['Int'];
  premiumCounter: Scalars['Int'];
};

/** An enumeration. */
export enum SiteMessagesMessageLocationChoices {
  /** Anonymous, member finished features */
  AnonymousMemberFinishedFeatures = 'ANONYMOUS_MEMBER_FINISHED_FEATURES',
  /** Last feature left entering feature card */
  LastFeatureEnteringFeatureCard = 'LAST_FEATURE_ENTERING_FEATURE_CARD',
  /** Message on top on feature card - last feature */
  LastFeatureOnTopOfFeatureCard = 'LAST_FEATURE_ON_TOP_OF_FEATURE_CARD',
  /** Message on top on feature card */
  MessageOnTopOfFeatureCard = 'MESSAGE_ON_TOP_OF_FEATURE_CARD',
  /** Message when user sees last article and does not have anymore to see - show on partial article on feature card */
  PartialMemberOnlyFeature = 'PARTIAL_MEMBER_ONLY_FEATURE',
  /** Registered while browsing and entering feature card */
  RegisteredEnteringFeatureCard = 'REGISTERED_ENTERING_FEATURE_CARD',
  /** User paid - congrats */
  UserPaidCongrats = 'USER_PAID_CONGRATS'
}

/** An enumeration. */
export enum SiteMessagesMessageUserRoleChoices {
  /** Anonymous */
  Anonymous = 'ANONYMOUS',
  /** Registered */
  Registered = 'REGISTERED'
}

export type SubscriptionType = {
  __typename?: 'SubscriptionType';
  currency?: Maybe<CurrencyEnum>;
  duration?: Maybe<DurationEnum>;
  id: Scalars['ID'];
  isFreeTrial: Scalars['Boolean'];
  isMain?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<ItemType>>>;
  itemsBought?: Maybe<Array<Maybe<ItemsBoughtType>>>;
  mainTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  onlyForExpiredTrial?: Maybe<Scalars['Boolean']>;
  price: Scalars['Decimal'];
  pricingTitle?: Maybe<Scalars['String']>;
};

export type SubscriptionsPageType = {
  __typename?: 'SubscriptionsPageType';
  subscriptions?: Maybe<Array<Maybe<SubscriptionType>>>;
  title?: Maybe<Scalars['String']>;
};

export type TagType = {
  __typename?: 'TagType';
  group?: Maybe<GroupType>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UnFollowGroup = {
  __typename?: 'UnFollowGroup';
  errors?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type UnFollowTag = {
  __typename?: 'UnFollowTag';
  errors?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type UpdateOrder = {
  __typename?: 'UpdateOrder';
  errors?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  order?: Maybe<OrderType>;
};

export type UpdateUserInput = {
  avatar?: InputMaybe<Scalars['String']>;
  features?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type UpdateUserProfile = {
  __typename?: 'UpdateUserProfile';
  errors?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserType>;
};

export type UserGroupType = {
  __typename?: 'UserGroupType';
  features?: Maybe<Array<Maybe<FeatureType>>>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type UserType = {
  __typename?: 'UserType';
  avatar?: Maybe<Scalars['String']>;
  dateJoined?: Maybe<Scalars['Date']>;
  /** Required. 254 characters or fewer. Letters, digits and @/./+/-/_ only. */
  email?: Maybe<Scalars['String']>;
  /** saved features */
  features?: Maybe<Array<Maybe<FeatureType>>>;
  /** following groups */
  groups?: Maybe<Array<Maybe<UserGroupType>>>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isActiveSubscription?: Maybe<Scalars['Boolean']>;
  isSuperuser?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  /** following tags */
  tags?: Maybe<Array<Maybe<TagType>>>;
  useFreeTrialPeriod?: Maybe<Scalars['Boolean']>;
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type FeatureTypeFragmentFragment = { __typename?: 'FeatureType', id: string, title: string, description?: string | null, isOnlyMember?: boolean | null, created?: any | null, isSaved?: boolean | null, savedFeature?: number | null, previewDescription?: string | null, tags?: Array<{ __typename?: 'TagType', id: string, name: string, image?: string | null, group?: { __typename?: 'GroupType', id: string, name: string } | null } | null> | null };

export type GroupTypeFragmentFragment = { __typename?: 'GroupType', id: string, name: string };

export type OrderTypeFragmentFragment = { __typename?: 'OrderType', id: string, begins?: any | null, created?: any | null, status?: OrderStatusEnum | null, nextBillingDate?: any | null, subscription?: { __typename?: 'SubscriptionType', id: string, mainTitle?: string | null, name: string, pricingTitle?: string | null, duration?: DurationEnum | null, price: any, currency?: CurrencyEnum | null, isFreeTrial: boolean, isMain?: boolean | null, onlyForExpiredTrial?: boolean | null, items?: Array<{ __typename?: 'ItemType', id: string, index: number, title?: string | null } | null> | null, itemsBought?: Array<{ __typename?: 'ItemsBoughtType', id: string, index: number, title?: string | null } | null> | null } | null };

export type SubscriptionTypeFragmentFragment = { __typename?: 'SubscriptionType', id: string, mainTitle?: string | null, name: string, pricingTitle?: string | null, duration?: DurationEnum | null, price: any, currency?: CurrencyEnum | null, isFreeTrial: boolean, isMain?: boolean | null, onlyForExpiredTrial?: boolean | null, items?: Array<{ __typename?: 'ItemType', id: string, index: number, title?: string | null } | null> | null, itemsBought?: Array<{ __typename?: 'ItemsBoughtType', id: string, index: number, title?: string | null } | null> | null };

export type TagTypeFragmentFragment = { __typename?: 'TagType', id: string, name: string, image?: string | null, group?: { __typename?: 'GroupType', id: string, name: string } | null };

export type UserTypeFragmentFragment = { __typename?: 'UserType', id: string, email?: string | null, name?: string | null, avatar?: string | null, isActive?: boolean | null, isSuperuser?: boolean | null, dateJoined?: any | null, isActiveSubscription?: boolean | null, useFreeTrialPeriod?: boolean | null, features?: Array<{ __typename?: 'FeatureType', id: string, title: string, description?: string | null, isOnlyMember?: boolean | null, created?: any | null, isSaved?: boolean | null, savedFeature?: number | null, previewDescription?: string | null, tags?: Array<{ __typename?: 'TagType', id: string, name: string, image?: string | null, group?: { __typename?: 'GroupType', id: string, name: string } | null } | null> | null } | null> | null, tags?: Array<{ __typename?: 'TagType', id: string, image?: string | null, name: string } | null> | null, groups?: Array<{ __typename?: 'UserGroupType', group?: string | null } | null> | null };

export type CancelOrderMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type CancelOrderMutation = { __typename?: 'Mutations', cancelOrder?: { __typename?: 'CancelOrder', ok?: boolean | null, errors?: string | null } | null };

export type CreateOrderMutationVariables = Exact<{
  orderData: OrderInput;
}>;


export type CreateOrderMutation = { __typename?: 'Mutations', createOrder?: { __typename?: 'CreateOrder', checkoutUrl?: string | null, ok?: boolean | null, errors?: string | null } | null };

export type FollowGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type FollowGroupMutation = { __typename?: 'Mutations', followGroup?: { __typename?: 'FollowGroup', ok?: boolean | null, errors?: string | null } | null };

export type FollowTagMutationVariables = Exact<{
  tagId: Scalars['ID'];
}>;


export type FollowTagMutation = { __typename?: 'Mutations', followTag?: { __typename?: 'FollowTag', ok?: boolean | null, errors?: string | null } | null };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutations', forgotPassword?: { __typename?: 'ForgotPassword', ok?: boolean | null, errors?: string | null } | null };

export type GoogleAuthMutationVariables = Exact<{
  email: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
}>;


export type GoogleAuthMutation = { __typename?: 'Mutations', googleAuth?: { __typename?: 'GoogleAuth', errors?: string | null, token?: string | null, isExistUser?: boolean | null, user?: { __typename?: 'UserType', id: string, email?: string | null, name?: string | null, avatar?: string | null, isActive?: boolean | null, isSuperuser?: boolean | null, dateJoined?: any | null, isActiveSubscription?: boolean | null, useFreeTrialPeriod?: boolean | null, features?: Array<{ __typename?: 'FeatureType', id: string, title: string, description?: string | null, isOnlyMember?: boolean | null, created?: any | null, isSaved?: boolean | null, savedFeature?: number | null, previewDescription?: string | null, tags?: Array<{ __typename?: 'TagType', id: string, name: string, image?: string | null, group?: { __typename?: 'GroupType', id: string, name: string } | null } | null> | null } | null> | null, tags?: Array<{ __typename?: 'TagType', id: string, image?: string | null, name: string } | null> | null, groups?: Array<{ __typename?: 'UserGroupType', group?: string | null } | null> | null } | null } | null };

export type RemoveFeatureMutationVariables = Exact<{
  featureId: Scalars['ID'];
}>;


export type RemoveFeatureMutation = { __typename?: 'Mutations', removeFeature?: { __typename?: 'RemoveFeature', ok?: boolean | null, errors?: string | null } | null };

export type ResetForgotPasswordMutationVariables = Exact<{
  newPass: Scalars['String'];
  userId: Scalars['ID'];
}>;


export type ResetForgotPasswordMutation = { __typename?: 'Mutations', resetForgotPassword?: { __typename?: 'ResetForgotPassword', ok?: boolean | null } | null };

export type SaveFeatureMutationVariables = Exact<{
  featureId: Scalars['ID'];
}>;


export type SaveFeatureMutation = { __typename?: 'Mutations', saveFeature?: { __typename?: 'SaveFeature', ok?: boolean | null, errors?: string | null } | null };

export type SignInMutationVariables = Exact<{
  signin: SigninInput;
}>;


export type SignInMutation = { __typename?: 'Mutations', signin?: { __typename?: 'Signin', token?: string | null, errors?: string | null, user?: { __typename?: 'UserType', id: string, email?: string | null, name?: string | null, avatar?: string | null, isActive?: boolean | null, isSuperuser?: boolean | null, dateJoined?: any | null, isActiveSubscription?: boolean | null, useFreeTrialPeriod?: boolean | null, features?: Array<{ __typename?: 'FeatureType', id: string, title: string, description?: string | null, isOnlyMember?: boolean | null, created?: any | null, isSaved?: boolean | null, savedFeature?: number | null, previewDescription?: string | null, tags?: Array<{ __typename?: 'TagType', id: string, name: string, image?: string | null, group?: { __typename?: 'GroupType', id: string, name: string } | null } | null> | null } | null> | null, tags?: Array<{ __typename?: 'TagType', id: string, image?: string | null, name: string } | null> | null, groups?: Array<{ __typename?: 'UserGroupType', group?: string | null } | null> | null } | null } | null };

export type SignUpMutationVariables = Exact<{
  signup: SignupInput;
}>;


export type SignUpMutation = { __typename?: 'Mutations', signup?: { __typename?: 'Signup', ok?: boolean | null, token?: string | null, errors?: string | null, user?: { __typename?: 'UserType', id: string, email?: string | null, name?: string | null, avatar?: string | null, isActive?: boolean | null, isSuperuser?: boolean | null, dateJoined?: any | null, isActiveSubscription?: boolean | null, useFreeTrialPeriod?: boolean | null, features?: Array<{ __typename?: 'FeatureType', id: string, title: string, description?: string | null, isOnlyMember?: boolean | null, created?: any | null, isSaved?: boolean | null, savedFeature?: number | null, previewDescription?: string | null, tags?: Array<{ __typename?: 'TagType', id: string, name: string, image?: string | null, group?: { __typename?: 'GroupType', id: string, name: string } | null } | null> | null } | null> | null, tags?: Array<{ __typename?: 'TagType', id: string, image?: string | null, name: string } | null> | null, groups?: Array<{ __typename?: 'UserGroupType', group?: string | null } | null> | null } | null } | null };

export type UnfollowTagMutationVariables = Exact<{
  tagId: Scalars['ID'];
}>;


export type UnfollowTagMutation = { __typename?: 'Mutations', unfollowTag?: { __typename?: 'UnFollowTag', ok?: boolean | null, errors?: string | null } | null };

export type UpdateUserProfileMutationVariables = Exact<{
  userData: UpdateUserInput;
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutations', updateUserProfile?: { __typename?: 'UpdateUserProfile', ok?: boolean | null, errors?: string | null, user?: { __typename?: 'UserType', id: string, email?: string | null, name?: string | null, avatar?: string | null, isActive?: boolean | null, isSuperuser?: boolean | null, dateJoined?: any | null, isActiveSubscription?: boolean | null, useFreeTrialPeriod?: boolean | null, features?: Array<{ __typename?: 'FeatureType', id: string, title: string, description?: string | null, isOnlyMember?: boolean | null, created?: any | null, isSaved?: boolean | null, savedFeature?: number | null, previewDescription?: string | null, tags?: Array<{ __typename?: 'TagType', id: string, name: string, image?: string | null, group?: { __typename?: 'GroupType', id: string, name: string } | null } | null> | null } | null> | null, tags?: Array<{ __typename?: 'TagType', id: string, image?: string | null, name: string } | null> | null, groups?: Array<{ __typename?: 'UserGroupType', group?: string | null } | null> | null } | null } | null };

export type GetCountersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountersQuery = { __typename?: 'Query', getCounters?: { __typename?: 'SiteConfigurationType', globalCounter: number, premiumCounter: number } | null };

export type GetFeatureQueryVariables = Exact<{
  featureId: Scalars['ID'];
}>;


export type GetFeatureQuery = { __typename?: 'Query', getFeature?: { __typename?: 'FeatureType', id: string, title: string, description?: string | null, isOnlyMember?: boolean | null, created?: any | null, isSaved?: boolean | null, savedFeature?: number | null, previewDescription?: string | null, tags?: Array<{ __typename?: 'TagType', id: string, name: string, image?: string | null, group?: { __typename?: 'GroupType', id: string, name: string } | null } | null> | null } | null };

export type GetFeatureFollowingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeatureFollowingQuery = { __typename?: 'Query', getFeatureFollowing?: Array<{ __typename?: 'FeatureType', id: string, title: string, description?: string | null, isOnlyMember?: boolean | null, created?: any | null, isSaved?: boolean | null, savedFeature?: number | null, previewDescription?: string | null, tags?: Array<{ __typename?: 'TagType', id: string, name: string, image?: string | null, group?: { __typename?: 'GroupType', id: string, name: string } | null } | null> | null } | null> | null };

export type GetFeatureSavedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeatureSavedQuery = { __typename?: 'Query', getFeatureSaved?: Array<{ __typename?: 'FeatureType', id: string, title: string, description?: string | null, isOnlyMember?: boolean | null, created?: any | null, isSaved?: boolean | null, savedFeature?: number | null, previewDescription?: string | null, tags?: Array<{ __typename?: 'TagType', id: string, name: string, image?: string | null, group?: { __typename?: 'GroupType', id: string, name: string } | null } | null> | null } | null> | null };

export type GetFeaturesQueryVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;


export type GetFeaturesQuery = { __typename?: 'Query', getFeatures?: Array<{ __typename?: 'FeatureType', id: string, title: string, description?: string | null, isOnlyMember?: boolean | null, created?: any | null, isSaved?: boolean | null, savedFeature?: number | null, previewDescription?: string | null, tags?: Array<{ __typename?: 'TagType', id: string, name: string, image?: string | null, group?: { __typename?: 'GroupType', id: string, name: string } | null } | null> | null } | null> | null };

export type GetMessageQueryVariables = Exact<{
  location: MessageLocationEnum;
}>;


export type GetMessageQuery = { __typename?: 'Query', getMessage?: { __typename?: 'MessageType', id: string, text: string, btnText: string, location?: SiteMessagesMessageLocationChoices | null, userRole?: SiteMessagesMessageUserRoleChoices | null } | null };

export type GetNavbarTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNavbarTagsQuery = { __typename?: 'Query', getNavbarTags?: Array<{ __typename?: 'TagType', id: string, name: string, image?: string | null, group?: { __typename?: 'GroupType', id: string, name: string } | null } | null> | null };

export type GetOrderQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrderQuery = { __typename?: 'Query', getOrder?: { __typename?: 'OrderType', id: string, begins?: any | null, created?: any | null, status?: OrderStatusEnum | null, nextBillingDate?: any | null, subscription?: { __typename?: 'SubscriptionType', id: string, mainTitle?: string | null, name: string, pricingTitle?: string | null, duration?: DurationEnum | null, price: any, currency?: CurrencyEnum | null, isFreeTrial: boolean, isMain?: boolean | null, onlyForExpiredTrial?: boolean | null, items?: Array<{ __typename?: 'ItemType', id: string, index: number, title?: string | null } | null> | null, itemsBought?: Array<{ __typename?: 'ItemsBoughtType', id: string, index: number, title?: string | null } | null> | null } | null } | null };

export type GetOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrdersQuery = { __typename?: 'Query', getOrders?: Array<{ __typename?: 'OrderType', id: string, begins?: any | null, created?: any | null, status?: OrderStatusEnum | null, nextBillingDate?: any | null, subscription?: { __typename?: 'SubscriptionType', id: string, mainTitle?: string | null, name: string, pricingTitle?: string | null, duration?: DurationEnum | null, price: any, currency?: CurrencyEnum | null, isFreeTrial: boolean, isMain?: boolean | null, onlyForExpiredTrial?: boolean | null, items?: Array<{ __typename?: 'ItemType', id: string, index: number, title?: string | null } | null> | null, itemsBought?: Array<{ __typename?: 'ItemsBoughtType', id: string, index: number, title?: string | null } | null> | null } | null } | null> | null };

export type GetRelatedFeaturesQueryVariables = Exact<{
  featureId: Scalars['ID'];
}>;


export type GetRelatedFeaturesQuery = { __typename?: 'Query', getRelatedFeatures?: Array<{ __typename?: 'RelatedFeatureType', id?: string | null, created?: any | null, isOnlyMember?: boolean | null, title?: string | null, previewDescription?: string | null, tags?: Array<{ __typename?: 'TagType', id: string, name: string, image?: string | null, group?: { __typename?: 'GroupType', id: string, name: string } | null } | null> | null } | null> | null };

export type GetSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionsQuery = { __typename?: 'Query', getSubscriptions?: Array<{ __typename?: 'SubscriptionType', id: string, mainTitle?: string | null, name: string, pricingTitle?: string | null, duration?: DurationEnum | null, price: any, currency?: CurrencyEnum | null, isFreeTrial: boolean, isMain?: boolean | null, onlyForExpiredTrial?: boolean | null, items?: Array<{ __typename?: 'ItemType', id: string, index: number, title?: string | null } | null> | null, itemsBought?: Array<{ __typename?: 'ItemsBoughtType', id: string, index: number, title?: string | null } | null> | null } | null> | null };

export type GetSubscriptionsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionsPageQuery = { __typename?: 'Query', getSubscriptionsPage?: { __typename?: 'SubscriptionsPageType', title?: string | null, subscriptions?: Array<{ __typename?: 'SubscriptionType', id: string, mainTitle?: string | null, name: string, pricingTitle?: string | null, duration?: DurationEnum | null, price: any, currency?: CurrencyEnum | null, isFreeTrial: boolean, isMain?: boolean | null, onlyForExpiredTrial?: boolean | null, items?: Array<{ __typename?: 'ItemType', id: string, index: number, title?: string | null } | null> | null, itemsBought?: Array<{ __typename?: 'ItemsBoughtType', id: string, index: number, title?: string | null } | null> | null } | null> | null } | null };

export type GetTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTagsQuery = { __typename?: 'Query', getTags?: Array<{ __typename?: 'GroupTagType', group?: string | null, tags?: Array<{ __typename?: 'TagType', id: string, name: string, image?: string | null, group?: { __typename?: 'GroupType', id: string, name: string } | null } | null> | null } | null> | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'UserType', id: string, email?: string | null, name?: string | null, avatar?: string | null, isActive?: boolean | null, isSuperuser?: boolean | null, dateJoined?: any | null, isActiveSubscription?: boolean | null, useFreeTrialPeriod?: boolean | null, features?: Array<{ __typename?: 'FeatureType', id: string, title: string, description?: string | null, isOnlyMember?: boolean | null, created?: any | null, isSaved?: boolean | null, savedFeature?: number | null, previewDescription?: string | null, tags?: Array<{ __typename?: 'TagType', id: string, name: string, image?: string | null, group?: { __typename?: 'GroupType', id: string, name: string } | null } | null> | null } | null> | null, tags?: Array<{ __typename?: 'TagType', id: string, image?: string | null, name: string } | null> | null, groups?: Array<{ __typename?: 'UserGroupType', group?: string | null } | null> | null } | null };

export const SubscriptionTypeFragmentFragmentDoc = gql`
    fragment SubscriptionTypeFragment on SubscriptionType {
  id
  mainTitle
  name
  pricingTitle
  duration
  price
  currency
  isFreeTrial
  isMain
  onlyForExpiredTrial
  items {
    id
    index
    title
  }
  itemsBought {
    id
    index
    title
  }
}
    `;
export const OrderTypeFragmentFragmentDoc = gql`
    fragment OrderTypeFragment on OrderType {
  id
  begins
  created
  status
  nextBillingDate
  subscription {
    ...SubscriptionTypeFragment
  }
}
    ${SubscriptionTypeFragmentFragmentDoc}`;
export const GroupTypeFragmentFragmentDoc = gql`
    fragment GroupTypeFragment on GroupType {
  id
  name
}
    `;
export const TagTypeFragmentFragmentDoc = gql`
    fragment TagTypeFragment on TagType {
  id
  name
  group {
    ...GroupTypeFragment
  }
  image
}
    ${GroupTypeFragmentFragmentDoc}`;
export const FeatureTypeFragmentFragmentDoc = gql`
    fragment FeatureTypeFragment on FeatureType {
  id
  title
  description
  isOnlyMember
  tags {
    ...TagTypeFragment
  }
  created
  isSaved
  savedFeature
  previewDescription
}
    ${TagTypeFragmentFragmentDoc}`;
export const UserTypeFragmentFragmentDoc = gql`
    fragment UserTypeFragment on UserType {
  id
  email
  name
  avatar
  isActive
  isSuperuser
  dateJoined
  isActiveSubscription
  useFreeTrialPeriod
  features {
    ...FeatureTypeFragment
  }
  tags {
    id
    image
    name
  }
  groups {
    group
  }
}
    ${FeatureTypeFragmentFragmentDoc}`;
export const CancelOrderDocument = gql`
    mutation CancelOrder($orderId: ID!) {
  cancelOrder(orderId: $orderId) {
    ok
    errors
  }
}
    `;
export type CancelOrderMutationFn = Apollo.MutationFunction<CancelOrderMutation, CancelOrderMutationVariables>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCancelOrderMutation(baseOptions?: Apollo.MutationHookOptions<CancelOrderMutation, CancelOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(CancelOrderDocument, options);
      }
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = Apollo.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<CancelOrderMutation, CancelOrderMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($orderData: OrderInput!) {
  createOrder(orderData: $orderData) {
    checkoutUrl
    ok
    errors
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      orderData: // value for 'orderData'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const FollowGroupDocument = gql`
    mutation FollowGroup($groupId: ID!) {
  followGroup(groupId: $groupId) {
    ok
    errors
  }
}
    `;
export type FollowGroupMutationFn = Apollo.MutationFunction<FollowGroupMutation, FollowGroupMutationVariables>;

/**
 * __useFollowGroupMutation__
 *
 * To run a mutation, you first call `useFollowGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followGroupMutation, { data, loading, error }] = useFollowGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useFollowGroupMutation(baseOptions?: Apollo.MutationHookOptions<FollowGroupMutation, FollowGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FollowGroupMutation, FollowGroupMutationVariables>(FollowGroupDocument, options);
      }
export type FollowGroupMutationHookResult = ReturnType<typeof useFollowGroupMutation>;
export type FollowGroupMutationResult = Apollo.MutationResult<FollowGroupMutation>;
export type FollowGroupMutationOptions = Apollo.BaseMutationOptions<FollowGroupMutation, FollowGroupMutationVariables>;
export const FollowTagDocument = gql`
    mutation FollowTag($tagId: ID!) {
  followTag(tagId: $tagId) {
    ok
    errors
  }
}
    `;
export type FollowTagMutationFn = Apollo.MutationFunction<FollowTagMutation, FollowTagMutationVariables>;

/**
 * __useFollowTagMutation__
 *
 * To run a mutation, you first call `useFollowTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followTagMutation, { data, loading, error }] = useFollowTagMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useFollowTagMutation(baseOptions?: Apollo.MutationHookOptions<FollowTagMutation, FollowTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FollowTagMutation, FollowTagMutationVariables>(FollowTagDocument, options);
      }
export type FollowTagMutationHookResult = ReturnType<typeof useFollowTagMutation>;
export type FollowTagMutationResult = Apollo.MutationResult<FollowTagMutation>;
export type FollowTagMutationOptions = Apollo.BaseMutationOptions<FollowTagMutation, FollowTagMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
    errors
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const GoogleAuthDocument = gql`
    mutation GoogleAuth($email: String!, $name: String) {
  googleAuth(email: $email, name: $name) {
    errors
    token
    isExistUser
    user {
      ...UserTypeFragment
    }
  }
}
    ${UserTypeFragmentFragmentDoc}`;
export type GoogleAuthMutationFn = Apollo.MutationFunction<GoogleAuthMutation, GoogleAuthMutationVariables>;

/**
 * __useGoogleAuthMutation__
 *
 * To run a mutation, you first call `useGoogleAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleAuthMutation, { data, loading, error }] = useGoogleAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGoogleAuthMutation(baseOptions?: Apollo.MutationHookOptions<GoogleAuthMutation, GoogleAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleAuthMutation, GoogleAuthMutationVariables>(GoogleAuthDocument, options);
      }
export type GoogleAuthMutationHookResult = ReturnType<typeof useGoogleAuthMutation>;
export type GoogleAuthMutationResult = Apollo.MutationResult<GoogleAuthMutation>;
export type GoogleAuthMutationOptions = Apollo.BaseMutationOptions<GoogleAuthMutation, GoogleAuthMutationVariables>;
export const RemoveFeatureDocument = gql`
    mutation RemoveFeature($featureId: ID!) {
  removeFeature(featureId: $featureId) {
    ok
    errors
  }
}
    `;
export type RemoveFeatureMutationFn = Apollo.MutationFunction<RemoveFeatureMutation, RemoveFeatureMutationVariables>;

/**
 * __useRemoveFeatureMutation__
 *
 * To run a mutation, you first call `useRemoveFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFeatureMutation, { data, loading, error }] = useRemoveFeatureMutation({
 *   variables: {
 *      featureId: // value for 'featureId'
 *   },
 * });
 */
export function useRemoveFeatureMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFeatureMutation, RemoveFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFeatureMutation, RemoveFeatureMutationVariables>(RemoveFeatureDocument, options);
      }
export type RemoveFeatureMutationHookResult = ReturnType<typeof useRemoveFeatureMutation>;
export type RemoveFeatureMutationResult = Apollo.MutationResult<RemoveFeatureMutation>;
export type RemoveFeatureMutationOptions = Apollo.BaseMutationOptions<RemoveFeatureMutation, RemoveFeatureMutationVariables>;
export const ResetForgotPasswordDocument = gql`
    mutation ResetForgotPassword($newPass: String!, $userId: ID!) {
  resetForgotPassword(newPass: $newPass, userId: $userId) {
    ok
  }
}
    `;
export type ResetForgotPasswordMutationFn = Apollo.MutationFunction<ResetForgotPasswordMutation, ResetForgotPasswordMutationVariables>;

/**
 * __useResetForgotPasswordMutation__
 *
 * To run a mutation, you first call `useResetForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetForgotPasswordMutation, { data, loading, error }] = useResetForgotPasswordMutation({
 *   variables: {
 *      newPass: // value for 'newPass'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResetForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetForgotPasswordMutation, ResetForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetForgotPasswordMutation, ResetForgotPasswordMutationVariables>(ResetForgotPasswordDocument, options);
      }
export type ResetForgotPasswordMutationHookResult = ReturnType<typeof useResetForgotPasswordMutation>;
export type ResetForgotPasswordMutationResult = Apollo.MutationResult<ResetForgotPasswordMutation>;
export type ResetForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ResetForgotPasswordMutation, ResetForgotPasswordMutationVariables>;
export const SaveFeatureDocument = gql`
    mutation SaveFeature($featureId: ID!) {
  saveFeature(featureId: $featureId) {
    ok
    errors
  }
}
    `;
export type SaveFeatureMutationFn = Apollo.MutationFunction<SaveFeatureMutation, SaveFeatureMutationVariables>;

/**
 * __useSaveFeatureMutation__
 *
 * To run a mutation, you first call `useSaveFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFeatureMutation, { data, loading, error }] = useSaveFeatureMutation({
 *   variables: {
 *      featureId: // value for 'featureId'
 *   },
 * });
 */
export function useSaveFeatureMutation(baseOptions?: Apollo.MutationHookOptions<SaveFeatureMutation, SaveFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveFeatureMutation, SaveFeatureMutationVariables>(SaveFeatureDocument, options);
      }
export type SaveFeatureMutationHookResult = ReturnType<typeof useSaveFeatureMutation>;
export type SaveFeatureMutationResult = Apollo.MutationResult<SaveFeatureMutation>;
export type SaveFeatureMutationOptions = Apollo.BaseMutationOptions<SaveFeatureMutation, SaveFeatureMutationVariables>;
export const SignInDocument = gql`
    mutation SignIn($signin: SigninInput!) {
  signin(signin: $signin) {
    token
    user {
      ...UserTypeFragment
    }
    errors
  }
}
    ${UserTypeFragmentFragmentDoc}`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      signin: // value for 'signin'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($signup: SignupInput!) {
  signup(signup: $signup) {
    ok
    token
    user {
      ...UserTypeFragment
    }
    errors
  }
}
    ${UserTypeFragmentFragmentDoc}`;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      signup: // value for 'signup'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const UnfollowTagDocument = gql`
    mutation UnfollowTag($tagId: ID!) {
  unfollowTag(tagId: $tagId) {
    ok
    errors
  }
}
    `;
export type UnfollowTagMutationFn = Apollo.MutationFunction<UnfollowTagMutation, UnfollowTagMutationVariables>;

/**
 * __useUnfollowTagMutation__
 *
 * To run a mutation, you first call `useUnfollowTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowTagMutation, { data, loading, error }] = useUnfollowTagMutation({
 *   variables: {
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useUnfollowTagMutation(baseOptions?: Apollo.MutationHookOptions<UnfollowTagMutation, UnfollowTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnfollowTagMutation, UnfollowTagMutationVariables>(UnfollowTagDocument, options);
      }
export type UnfollowTagMutationHookResult = ReturnType<typeof useUnfollowTagMutation>;
export type UnfollowTagMutationResult = Apollo.MutationResult<UnfollowTagMutation>;
export type UnfollowTagMutationOptions = Apollo.BaseMutationOptions<UnfollowTagMutation, UnfollowTagMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($userData: UpdateUserInput!) {
  updateUserProfile(userData: $userData) {
    ok
    errors
    user {
      ...UserTypeFragment
    }
  }
}
    ${UserTypeFragmentFragmentDoc}`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      userData: // value for 'userData'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const GetCountersDocument = gql`
    query GetCounters {
  getCounters {
    globalCounter
    premiumCounter
  }
}
    `;

/**
 * __useGetCountersQuery__
 *
 * To run a query within a React component, call `useGetCountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountersQuery(baseOptions?: Apollo.QueryHookOptions<GetCountersQuery, GetCountersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountersQuery, GetCountersQueryVariables>(GetCountersDocument, options);
      }
export function useGetCountersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountersQuery, GetCountersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountersQuery, GetCountersQueryVariables>(GetCountersDocument, options);
        }
export type GetCountersQueryHookResult = ReturnType<typeof useGetCountersQuery>;
export type GetCountersLazyQueryHookResult = ReturnType<typeof useGetCountersLazyQuery>;
export type GetCountersQueryResult = Apollo.QueryResult<GetCountersQuery, GetCountersQueryVariables>;
export const GetFeatureDocument = gql`
    query GetFeature($featureId: ID!) {
  getFeature(featureId: $featureId) {
    ...FeatureTypeFragment
  }
}
    ${FeatureTypeFragmentFragmentDoc}`;

/**
 * __useGetFeatureQuery__
 *
 * To run a query within a React component, call `useGetFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureQuery({
 *   variables: {
 *      featureId: // value for 'featureId'
 *   },
 * });
 */
export function useGetFeatureQuery(baseOptions: Apollo.QueryHookOptions<GetFeatureQuery, GetFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureQuery, GetFeatureQueryVariables>(GetFeatureDocument, options);
      }
export function useGetFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureQuery, GetFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureQuery, GetFeatureQueryVariables>(GetFeatureDocument, options);
        }
export type GetFeatureQueryHookResult = ReturnType<typeof useGetFeatureQuery>;
export type GetFeatureLazyQueryHookResult = ReturnType<typeof useGetFeatureLazyQuery>;
export type GetFeatureQueryResult = Apollo.QueryResult<GetFeatureQuery, GetFeatureQueryVariables>;
export const GetFeatureFollowingDocument = gql`
    query GetFeatureFollowing {
  getFeatureFollowing {
    ...FeatureTypeFragment
  }
}
    ${FeatureTypeFragmentFragmentDoc}`;

/**
 * __useGetFeatureFollowingQuery__
 *
 * To run a query within a React component, call `useGetFeatureFollowingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFollowingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFollowingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeatureFollowingQuery(baseOptions?: Apollo.QueryHookOptions<GetFeatureFollowingQuery, GetFeatureFollowingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureFollowingQuery, GetFeatureFollowingQueryVariables>(GetFeatureFollowingDocument, options);
      }
export function useGetFeatureFollowingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureFollowingQuery, GetFeatureFollowingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureFollowingQuery, GetFeatureFollowingQueryVariables>(GetFeatureFollowingDocument, options);
        }
export type GetFeatureFollowingQueryHookResult = ReturnType<typeof useGetFeatureFollowingQuery>;
export type GetFeatureFollowingLazyQueryHookResult = ReturnType<typeof useGetFeatureFollowingLazyQuery>;
export type GetFeatureFollowingQueryResult = Apollo.QueryResult<GetFeatureFollowingQuery, GetFeatureFollowingQueryVariables>;
export const GetFeatureSavedDocument = gql`
    query GetFeatureSaved {
  getFeatureSaved {
    ...FeatureTypeFragment
  }
}
    ${FeatureTypeFragmentFragmentDoc}`;

/**
 * __useGetFeatureSavedQuery__
 *
 * To run a query within a React component, call `useGetFeatureSavedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureSavedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureSavedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeatureSavedQuery(baseOptions?: Apollo.QueryHookOptions<GetFeatureSavedQuery, GetFeatureSavedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureSavedQuery, GetFeatureSavedQueryVariables>(GetFeatureSavedDocument, options);
      }
export function useGetFeatureSavedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureSavedQuery, GetFeatureSavedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureSavedQuery, GetFeatureSavedQueryVariables>(GetFeatureSavedDocument, options);
        }
export type GetFeatureSavedQueryHookResult = ReturnType<typeof useGetFeatureSavedQuery>;
export type GetFeatureSavedLazyQueryHookResult = ReturnType<typeof useGetFeatureSavedLazyQuery>;
export type GetFeatureSavedQueryResult = Apollo.QueryResult<GetFeatureSavedQuery, GetFeatureSavedQueryVariables>;
export const GetFeaturesDocument = gql`
    query GetFeatures($title: String, $tags: [ID], $groups: [ID]) {
  getFeatures(title: $title, tags: $tags, groups: $groups) {
    ...FeatureTypeFragment
  }
}
    ${FeatureTypeFragmentFragmentDoc}`;

/**
 * __useGetFeaturesQuery__
 *
 * To run a query within a React component, call `useGetFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturesQuery({
 *   variables: {
 *      title: // value for 'title'
 *      tags: // value for 'tags'
 *      groups: // value for 'groups'
 *   },
 * });
 */
export function useGetFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<GetFeaturesQuery, GetFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeaturesQuery, GetFeaturesQueryVariables>(GetFeaturesDocument, options);
      }
export function useGetFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturesQuery, GetFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeaturesQuery, GetFeaturesQueryVariables>(GetFeaturesDocument, options);
        }
export type GetFeaturesQueryHookResult = ReturnType<typeof useGetFeaturesQuery>;
export type GetFeaturesLazyQueryHookResult = ReturnType<typeof useGetFeaturesLazyQuery>;
export type GetFeaturesQueryResult = Apollo.QueryResult<GetFeaturesQuery, GetFeaturesQueryVariables>;
export const GetMessageDocument = gql`
    query GetMessage($location: MessageLocationEnum!) {
  getMessage(location: $location) {
    id
    text
    btnText
    location
    userRole
  }
}
    `;

/**
 * __useGetMessageQuery__
 *
 * To run a query within a React component, call `useGetMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageQuery({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useGetMessageQuery(baseOptions: Apollo.QueryHookOptions<GetMessageQuery, GetMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageQuery, GetMessageQueryVariables>(GetMessageDocument, options);
      }
export function useGetMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageQuery, GetMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageQuery, GetMessageQueryVariables>(GetMessageDocument, options);
        }
export type GetMessageQueryHookResult = ReturnType<typeof useGetMessageQuery>;
export type GetMessageLazyQueryHookResult = ReturnType<typeof useGetMessageLazyQuery>;
export type GetMessageQueryResult = Apollo.QueryResult<GetMessageQuery, GetMessageQueryVariables>;
export const GetNavbarTagsDocument = gql`
    query GetNavbarTags {
  getNavbarTags {
    ...TagTypeFragment
  }
}
    ${TagTypeFragmentFragmentDoc}`;

/**
 * __useGetNavbarTagsQuery__
 *
 * To run a query within a React component, call `useGetNavbarTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavbarTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavbarTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNavbarTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetNavbarTagsQuery, GetNavbarTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNavbarTagsQuery, GetNavbarTagsQueryVariables>(GetNavbarTagsDocument, options);
      }
export function useGetNavbarTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNavbarTagsQuery, GetNavbarTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNavbarTagsQuery, GetNavbarTagsQueryVariables>(GetNavbarTagsDocument, options);
        }
export type GetNavbarTagsQueryHookResult = ReturnType<typeof useGetNavbarTagsQuery>;
export type GetNavbarTagsLazyQueryHookResult = ReturnType<typeof useGetNavbarTagsLazyQuery>;
export type GetNavbarTagsQueryResult = Apollo.QueryResult<GetNavbarTagsQuery, GetNavbarTagsQueryVariables>;
export const GetOrderDocument = gql`
    query GetOrder {
  getOrder {
    ...OrderTypeFragment
  }
}
    ${OrderTypeFragmentFragmentDoc}`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions?: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
      }
export function useGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
        }
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const GetOrdersDocument = gql`
    query GetOrders {
  getOrders {
    ...OrderTypeFragment
  }
}
    ${OrderTypeFragmentFragmentDoc}`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetRelatedFeaturesDocument = gql`
    query GetRelatedFeatures($featureId: ID!) {
  getRelatedFeatures(featureId: $featureId) {
    id
    created
    isOnlyMember
    title
    previewDescription
    tags {
      ...TagTypeFragment
    }
  }
}
    ${TagTypeFragmentFragmentDoc}`;

/**
 * __useGetRelatedFeaturesQuery__
 *
 * To run a query within a React component, call `useGetRelatedFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelatedFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelatedFeaturesQuery({
 *   variables: {
 *      featureId: // value for 'featureId'
 *   },
 * });
 */
export function useGetRelatedFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetRelatedFeaturesQuery, GetRelatedFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRelatedFeaturesQuery, GetRelatedFeaturesQueryVariables>(GetRelatedFeaturesDocument, options);
      }
export function useGetRelatedFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRelatedFeaturesQuery, GetRelatedFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRelatedFeaturesQuery, GetRelatedFeaturesQueryVariables>(GetRelatedFeaturesDocument, options);
        }
export type GetRelatedFeaturesQueryHookResult = ReturnType<typeof useGetRelatedFeaturesQuery>;
export type GetRelatedFeaturesLazyQueryHookResult = ReturnType<typeof useGetRelatedFeaturesLazyQuery>;
export type GetRelatedFeaturesQueryResult = Apollo.QueryResult<GetRelatedFeaturesQuery, GetRelatedFeaturesQueryVariables>;
export const GetSubscriptionsDocument = gql`
    query GetSubscriptions {
  getSubscriptions {
    ...SubscriptionTypeFragment
  }
}
    ${SubscriptionTypeFragmentFragmentDoc}`;

/**
 * __useGetSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
      }
export function useGetSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
        }
export type GetSubscriptionsQueryHookResult = ReturnType<typeof useGetSubscriptionsQuery>;
export type GetSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsLazyQuery>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>;
export const GetSubscriptionsPageDocument = gql`
    query GetSubscriptionsPage {
  getSubscriptionsPage {
    title
    subscriptions {
      ...SubscriptionTypeFragment
    }
  }
}
    ${SubscriptionTypeFragmentFragmentDoc}`;

/**
 * __useGetSubscriptionsPageQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionsPageQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionsPageQuery, GetSubscriptionsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionsPageQuery, GetSubscriptionsPageQueryVariables>(GetSubscriptionsPageDocument, options);
      }
export function useGetSubscriptionsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionsPageQuery, GetSubscriptionsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionsPageQuery, GetSubscriptionsPageQueryVariables>(GetSubscriptionsPageDocument, options);
        }
export type GetSubscriptionsPageQueryHookResult = ReturnType<typeof useGetSubscriptionsPageQuery>;
export type GetSubscriptionsPageLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsPageLazyQuery>;
export type GetSubscriptionsPageQueryResult = Apollo.QueryResult<GetSubscriptionsPageQuery, GetSubscriptionsPageQueryVariables>;
export const GetTagsDocument = gql`
    query GetTags {
  getTags {
    group
    tags {
      id
      name
      group {
        id
        name
      }
      image
    }
  }
}
    `;

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
      }
export function useGetTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
        }
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsQueryResult = Apollo.QueryResult<GetTagsQuery, GetTagsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...UserTypeFragment
  }
}
    ${UserTypeFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    