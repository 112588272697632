import React, {FC} from 'react';
import classNames from 'classnames/bind'
import s from './index.module.scss'
import {Button, Typography} from "components/modules";
import {FontWeight, TypographyVariant} from "helpers/types";
import {useNavigate} from "react-router-dom";

const cx = classNames.bind(s)
type PaymentSuccessPropsType = {}
export const PaymentSuccess: FC<PaymentSuccessPropsType> = () => {
  const navigate = useNavigate()
  return (
    <div className={cx('Component')}>
      <div className={cx('Content')}>
        <svg width="84" height="63" viewBox="0 0 84 63" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M26 51L8.50004 33.5C8.04243 33.0368 7.49742 32.6691 6.89662 32.4181C6.29581 32.1671 5.65117 32.0378 5.00004 32.0378C4.34892 32.0378 3.70426 32.1671 3.10346 32.4181C2.50266 32.6691 1.95765 33.0368 1.50004 33.5C1.03684 33.9577 0.669083 34.5027 0.418085 35.1035C0.167087 35.7043 0.0378418 36.3489 0.0378418 37C0.0378418 37.6512 0.167087 38.2958 0.418085 38.8966C0.669083 39.4974 1.03684 40.0424 1.50004 40.5L22.45 61.45C24.4 63.4 27.55 63.4 29.5 61.45L82.5 8.50004C82.9632 8.04242 83.331 7.49742 83.582 6.89662C83.833 6.29581 83.9622 5.65116 83.9622 5.00004C83.9622 4.34891 83.833 3.70426 83.582 3.10346C83.331 2.50266 82.9632 1.95765 82.5 1.50004C82.0424 1.03684 81.4974 0.669082 80.8966 0.418084C80.2958 0.167086 79.6512 0.0378418 79 0.0378418C78.3489 0.0378418 77.7043 0.167086 77.1035 0.418084C76.5027 0.669082 75.9577 1.03684 75.5 1.50004L26 51Z"
            fill="#7ACC53"/>
        </svg>
        <Typography
          variant={TypographyVariant.h32}
          weight={FontWeight.SemiBold}
        >Thank you!</Typography>

        <Typography variant={TypographyVariant.p16}>
          The payment was successful, the payment statement was sent to your email.
        </Typography>

        <Button size={'large'} onClick={() => navigate('/features')}>Continue</Button>
      </div>
    </div>
  );
};

