import {Maybe} from 'apollo/types_schema';
import React, {FC} from 'react';
import s from './index.module.scss'
import {StarIcon} from "../../../assets/Icons/starIcon";

type AvatarPropsType = {
  avatar?: Maybe<string>,
  firstName?: Maybe<string>,
  className?: string
  classNameComponent?: string
  classNameLabel?: string,
  label?: any,
  isMember: boolean

}
export const Avatar: FC<AvatarPropsType> =
  ({

     avatar,
     firstName,
     className,
     classNameComponent,
     classNameLabel,
     label,
     isMember

   }) => {
    return (
      <div className={`${s.Component} ${classNameComponent || ''}`}>
        <div className={s.WrapImage}>

          {isMember ? <StarIcon className={s.StarIcon}/> : null}
          <div className={`${s.Avatar} ${className || ''}`}>
          {avatar
            ? <img src={avatar ?? ''} alt=""/>
            : firstName ? firstName?.charAt(0) : '?'}
        </div>
        </div>
        {label && (
          <p
            className={classNameLabel || ''}
          >{label}</p>
        )}
      </div>
    );
  };

