import {useState} from "react";
import {useDispatch} from "react-redux";
import {editBase, ModalTypeEnum} from "../../store/reducers/base";
import {useStore} from "./index";
import {useLocation} from "react-router-dom";


export function useModalSign<T>() {
  const {isOpenModalSign, typeModalSign} = useStore(state => state.base)
  const [payload, setPayload] = useState<T | null>(null)
  const {pathname} = useLocation()
  const isSubscriptionPage = pathname.includes('/subscription/')
  const dispatch = useDispatch()
  const openModalSign = (payload?: T) => {
    dispatch(editBase({field: 'isOpenModalSign', value: true}))
    payload && setPayload(payload)
  }
  const closeModalSign = () => {
    dispatch(editBase({field: 'isOpenModalSign', value: false}))
    dispatch(editBase({field: 'typeModalSign', value: ModalTypeEnum.SignUp}))
    payload && setPayload(null)
    if (isSubscriptionPage && document.referrer) {
      window.history.back();
      // window.location.assign(document.referrer)
    }
  }
  const setTypeModalSign = (type: ModalTypeEnum) => dispatch(editBase({field: 'typeModalSign', value: type}))
  return {
    openModalSign,
    closeModalSign,
    setTypeModalSign,
    typeModalSign,
    isOpenModalSign,
    payloadModalSign: payload
  }
}
