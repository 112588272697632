import {ButtonProps, ButtonPropsAnchor, ButtonPropsLink} from "./index";

export type ButtonPropsType = {
  isOutline?: boolean,
  size?: 'large' | 'small'
  view?: 'default' | 'text'
  color?: ButtonColorEnum
} & (ButtonProps | ButtonPropsLink | ButtonPropsAnchor)

export enum ButtonColorEnum {
  Orange = 'orange',
  GhostBlue = 'ghost_blue',
  Blue = 'blue'

}
