import {ColorEnum, FontWeight, Orientation, TypographyVariant} from "helpers/types";
import {Button, Divider, Typography} from "components/modules";
import s from './index.module.scss';
import classNames from "classnames/bind";
import {useStore} from "helpers/hooks";
import * as React from "react";
import {FC} from "react";
import {
  GetFeatureDocument, GetFeatureFollowingDocument,
  GetFeaturesDocument,
  GetNavbarTagsDocument,
  MeDocument,
  useFollowTagMutation,
  useUnfollowTagMutation
} from "apollo/types_schema";
import {ButtonColorEnum} from "components/UI/Button/index.props";
import {useApolloClient} from "@apollo/client";
import {PlusIcon} from "assets/Icons/plusIcon";
import {FeaturesHeaderPropsType} from "./index.props";
import {useModalSign} from "helpers/hooks/useModalSign";
import {LOCAL_STORAGE_isHideFollow} from "modals/ModalFollow";
import {ModalFeatureEnum} from "../index";
import {ArrowIcon} from "assets/Icons/arrow";

const cx = classNames.bind(s);

export const FeaturesHeader: FC<FeaturesHeaderPropsType> =
  ({
     openModal,
     filtersList,
     handleTabClick,
     handleTagClick,
     activeTab,
     activeTag,
   }) => {
    const {isAuth, user} = useStore(state => state.base)
    const isFollowing = user?.tags?.some(tag => tag?.id === activeTag?.id)
    const {openModalSign} = useModalSign()
    const [followTagMutation, {loading: loadingFollow}] = useFollowTagMutation()
    const [unfollowTagMutation, {loading: loadingUnfollow}] = useUnfollowTagMutation()
    const apolloClient = useApolloClient()
    const handleFollow = async () => {
      if (!isAuth) {
        openModalSign()
        return;
      }
      if (!activeTag) return;

      if (isFollowing) {
        await unfollowTagMutation({
          variables: {
            tagId: activeTag.id
          },
          onCompleted(data) {
            if (data.unfollowTag?.ok) {
              apolloClient.refetchQueries({
                include: [MeDocument, GetFeaturesDocument, GetFeatureDocument, GetNavbarTagsDocument, GetFeatureFollowingDocument],
              })
            }
          },
        })

      } else {
        await followTagMutation({
          variables: {
            tagId: activeTag.id
          },
          onCompleted(data) {
            if (data.followTag?.ok) {
              apolloClient.refetchQueries({
                include: [MeDocument, GetFeaturesDocument, GetFeatureDocument, GetNavbarTagsDocument, GetFeatureFollowingDocument]
              })
              if (!window.localStorage.getItem(LOCAL_STORAGE_isHideFollow)) {
                openModal({view: ModalFeatureEnum.Follow, tagName: activeTag.name})
              }
            }
          },
        })

      }
    }
    if (activeTag) {
      return (
        <FeatureHeaderLayout>
          <div className={cx('TagTitle')}>
            <button
              onClick={() => handleTagClick(null)}
            ><ArrowIcon orientation={Orientation.Left}/></button>
            <Typography
              style={{marginLeft: 14}}
              color={ColorEnum.dark_gray} variant={TypographyVariant.h20}
              weight={FontWeight.SemiBold}>{activeTag?.name}</Typography>
            <Button
              disabled={loadingFollow || loadingUnfollow}
              onClick={handleFollow}
              className={cx('BtnFollowing')}
              color={isFollowing ? ButtonColorEnum.GhostBlue : ButtonColorEnum.Blue}>{
              isFollowing
                ? 'Following'
                : <span className={cx('BtnContent')}><PlusIcon/>Follow</span>}
            </Button>
          </div>
        </FeatureHeaderLayout>
      )
    }
    return (
      <FeatureHeaderLayout>
        <div className={cx('Tabs')}>
          {(isAuth ? filtersList : [filtersList[0]])?.map((tab) =>
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={cx('Tab', {TabActive: tab === activeTab})}
            >
              <Typography
                color={tab === activeTab ? ColorEnum.accent_gray : ColorEnum.gray}
                variant={TypographyVariant.p16}
              >{tab}</Typography>
            </button>
          )}
        </div>
      </FeatureHeaderLayout>
    )
  }
const FeatureHeaderLayout = ({children}: any) =>
  <header>
    <div>
      {children} </div>
    <Divider/>
  </header>
