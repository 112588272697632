import {TagType} from "../apollo/types_schema";

export enum FontWeight {
  SemiBold = 600,
  Regular = 400,
  Bold = 700
}

export enum ColorEnum {
  orange = '--color-orange',
  white = '--color-white',
  dark_gray = '--color-dark-gray',
  accent_gray = '--color-accent-gray',
  gray = '--color-gray',
  green = '--color-green',
  red = '--color-red'

}

export enum TypographyVariant {
  h32 = 'h32',
  h28 = 'h28',
  h24 = 'h24',
  h20 = 'h20',
  p18 = 'p18',
  p16 = 'p16',
  p14 = 'p14',
  p12 = 'p12'
}


export type DefaultIconPropsType = {
  orientation?: Orientation,
  fill?: ColorEnum | string,
  width?: number | string,
  height?: number | string,
  className?: string
}

export enum Orientation {
  Top,
  Right,
  Bottom,
  Left

}

export enum OrientationSymmetric {
  Vertical,
  Horizontal
}

export enum FormMessage {
  Required = 'Field is required',
  NotValid = 'Field is not valid',
}


export type NavbarTagListType = {
  [key: string]: TagType[]
}

export enum RedirectPayment {
  Success = 'True',
  Failed = 'False'
}
