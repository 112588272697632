import React from "react";

export function FavoriteIcon({isFavorite}: { isFavorite: boolean }) {
  return (
    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.55238 0.500542V0.273101L7.5547 0.500516L7.60518 0.500003C7.89134 0.5006 8.1486 0.590179 8.32401 0.726121C8.49601 0.859419 8.56059 1.01102 8.563 1.1354V12.0019L4.83142 9.20939L4.53187 8.98522L4.2323 9.20937L0.5 12.002V1.63154H0.500692V1.13485C0.503107 1.01057 0.567662 0.859057 0.739636 0.725843C0.915026 0.589984 1.17223 0.500502 1.45828 0.500002L1.50595 0.500513L1.50595 0.500542H1.51131H7.54962H7.55238Z"
        stroke={isFavorite ? "none" : "#979797"}
        fill={isFavorite ? "#FF994F" : "none"}
      />
    </svg>

  )
}