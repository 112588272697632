import * as Apollo from "@apollo/client";
import {
  FeatureType,
  GetFeatureDocument,
  GetFeatureFollowingDocument,
  GetFeatureFollowingQuery,
  GetFeatureQuery,
  GetFeatureQueryVariables,
  GetFeatureSavedDocument,
  GetFeatureSavedQuery, GetFeatureSavedQueryVariables,
  GetFeaturesDocument,
  GetFeaturesQuery,
  GetFeaturesQueryVariables,
  SaveFeatureMutation,
  SaveFeatureMutationVariables,
  useSaveFeatureMutation
} from "../types_schema";
import {toast} from "react-toastify";

export function useSaveFeatureWithCache(baseOptions?: Apollo.MutationHookOptions<SaveFeatureMutation, SaveFeatureMutationVariables>) {
  return useSaveFeatureMutation({
    ...baseOptions,
    update(cache, {data}, {variables, context}) {
      if (data?.saveFeature?.ok && !data?.saveFeature?.errors) {
        let cacheFeature = cache.readQuery<GetFeatureQuery>({
          query: GetFeatureDocument,
          variables: {featureId: variables?.featureId} as GetFeatureQueryVariables
        })
        let cacheFeatures = cache.readQuery<GetFeaturesQuery>({
          query: GetFeaturesDocument,
          variables: context as GetFeaturesQueryVariables

        })
        let cacheFeaturesSaved = cache.readQuery<GetFeatureSavedQuery>({
          query: GetFeatureSavedDocument,
        }) || null
        let cacheFeatureFollowing = cache.readQuery<GetFeatureFollowingQuery>({
          query: GetFeatureFollowingDocument
        })
        if (cacheFeature) {
          const newFeature = {
            ...cacheFeature,
            getFeature: {
              ...cacheFeature.getFeature,
              savedFeature: (cacheFeature.getFeature?.savedFeature ?? 0) + 1,
              isSaved: true
            }
          } as GetFeatureQuery
          cache.writeQuery<GetFeatureQuery>({
            query: GetFeatureDocument,
            data: newFeature
          })
        }
        if (cacheFeatures) {
          let newFeatures = [] as Array<FeatureType>;
          newFeatures = cacheFeatures?.getFeatures?.map(c => c?.id === variables?.featureId
            ? ({...c, isSaved: true, savedFeature: (c?.savedFeature ?? 0) + 1})
            : c) as Array<FeatureType>
          const newFeaturesQuery = {
            ...cacheFeatures, getFeatures: newFeatures
          } as GetFeaturesQuery


          cache.writeQuery<GetFeaturesQuery>({
            query: GetFeaturesDocument,
            data: newFeaturesQuery
          })
        }
        if (cacheFeaturesSaved) {
          let newFeatures = [] as Array<FeatureType>;
          let feature = cache.readQuery<GetFeaturesQuery>({
            query: GetFeaturesDocument,
            variables: context as GetFeaturesQueryVariables
          })?.getFeatures?.find(f => f?.id === variables?.featureId)
          newFeatures = [...(cacheFeaturesSaved?.getFeatureSaved ?? []), {
            ...feature,
            previewDescription: feature?.previewDescription,
            __typename: 'FeatureType'
          }] as Array<FeatureType>
          const newFeaturesQuery = {
            getFeatureSaved: newFeatures || []
          } as GetFeatureSavedQuery
          cache.writeQuery<GetFeatureSavedQuery>({
            query: GetFeatureSavedDocument,
            data: newFeaturesQuery
          })
        }
        if (cacheFeatureFollowing) {
          let newFeatures = [] as Array<FeatureType>;
          newFeatures = cacheFeatureFollowing?.getFeatureFollowing?.map(c => c?.id === variables?.featureId
            ? ({...c, isSaved: true, savedFeature: (c?.savedFeature ?? 0) + 1})
            : c) as Array<FeatureType>
          const newFeaturesQuery = {
            ...cacheFeatures, getFeatureFollowing: newFeatures
          } as GetFeatureFollowingQuery


          cache.writeQuery<GetFeatureFollowingQuery>({
            query: GetFeatureFollowingDocument,
            data: newFeaturesQuery
          })
        }
      } else if (data?.saveFeature?.errors) {
        toast(data?.saveFeature?.errors ?? 'Oops.. something failed', {type: 'error'})
      }
    }

  })
}

