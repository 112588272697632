import {ApolloClient, createHttpLink, from, InMemoryCache,} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {ErrorLink} from '@apollo/client/link/error';
import {REACT_APP_API, REACT_APP_API_BETA, REACT_APP_ENV} from '../variables/';
import {getJwtToken} from '../helpers/sign'

const httpLink = createHttpLink({
  uri: REACT_APP_ENV === 'beta' ? REACT_APP_API_BETA : REACT_APP_API
});
const errorLink = new ErrorLink((error) => {
  if (error.graphQLErrors?.[0]?.extensions) {


  }
  if ((error.networkError?.message)) {
    // toast(error.networkError.message, {type: 'error'})

  }
})
const authLink = setContext((operation, {headers}) => {
  const token = getJwtToken();
  return {
    headers: {
      ...headers,
      authorization: operation.operationName === "UpdateUserProfile" ? '' : (token ? `JWT ${token}` : ''),
      // 'X-CSRF-Token': getCSRFToken()
    }
  }
});
// const lngLink = setContext(((operation) => {
//   if (operation.variables) {
//     operation.variables.language = i18next.language.toUpperCase() || Language.En
//   }
// }))

const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      errorPolicy: 'ignore'
    },
    watchQuery: {
      notifyOnNetworkStatusChange: true,
    }
  }
});

export default client
