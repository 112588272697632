import React, {DetailsHTMLAttributes, FC, forwardRef} from 'react'
import {TypographyPropsType, variantsMapping} from 'components/UI/Typography/index.props'
import classNames from 'classnames/bind'
import s from './index.module.scss'
import {ColorEnum, FontWeight, TypographyVariant} from 'helpers/types'
import {parseChildren} from "helpers/parseChildren";

const cx = classNames.bind(s)

export const Typography: FC<TypographyPropsType & DetailsHTMLAttributes<HTMLParagraphElement | HTMLHeadingElement>>
  = forwardRef(({
                  variant = TypographyVariant.p14,
                  color = ColorEnum.accent_gray,
                  children,
                  weight = FontWeight.Regular,
                  className: propsClassName,
                  ...props
                }, ref): any => {
  const Component = variant ? variantsMapping[variant] : 'p'

  const classNames = cx(
    'typography', {
      [`typography--variant-${variant}`]: variant,
      [`typography--variant-${weight}`]: weight,
      [`typography${color}`]: color
    }) + ' ' + (propsClassName ?? '')
  return <Component
    ref={ref}
    className={classNames}
    {...props}
  >
    {parseChildren(children)}
  </Component>
})

