import React, {Fragment, lazy, Suspense, useEffect} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {Feature, Features, ForgotPassword, Membership, OurMembers, Platform, Subscription} from "pages/modules";
import {usePageView, useStore} from "helpers/hooks";
import {useMe} from "helpers/sign";
import {isDev, REACT_APP_API_REDIRECT_URL} from "variables/";
import {AdaptiveHelper, Loading} from "components/modules";
import PaymentRedirect from "pages/PaymentRedirect";
import {RoutePath} from "helpers/routes";
import {useDispatch} from "react-redux";
import {editBase} from "../store/reducers/base";
import {UserType} from "../apollo/types_schema";
import {useGoogleOneTap} from "../helpers/hooks/useGoogleLogin";

const NavBar = lazy(() => import('components/NavBar'))
const ModalAuth = lazy(() => import('modals/Auth'))

function App() {
  usePageView();
  useMe();
  const dispatch = useDispatch()
  const {user, isAuth} = useStore(state => state.base)
  return (
    <>
      {!isAuth && <OneTap/>}
      {isDev && <button onClick={() => dispatch(editBase({
        field: 'user',
        value: {...user, isActiveSubscription: !user?.isActiveSubscription} as UserType
      }))}>Toggle user subscribe</button>}
      {isDev && <AdaptiveHelper/>}
      <Suspense fallback={<Loading/>}>
        <div className="App">

          <NavBar/>
          <div className="container">

            <Routes>
              <Route
                path={RoutePath.Home}
                element={isDev ? <Navigate to={RoutePath.Features}/> : <Redirect to={REACT_APP_API_REDIRECT_URL}/>}
              />
              <Route
                path={RoutePath.ForgotPassword + "/:userId"}
                element={<ForgotPassword/>}
              />
              <Route path={RoutePath.Features} element={<Features/>}/>
              <Route path={RoutePath.Subscription + '/:id'} element={<Subscription/>}/>
              <Route path={RoutePath.Feature + "/:name/:id"} element={<Feature/>}/>
              <Route path="/our_members" element={<OurMembers/>}/>
              <Route path="/platform" element={<Platform/>}/>
              <Route path={RoutePath.PaymentRedirect} element={<PaymentRedirect/>}/>
              <Route path={RoutePath.Membership} element={<Membership/>}/>
            </Routes>

          </div>
        </div>
        <ModalAuth/>
      </Suspense>
    </>
  );
}

export default App;


function Redirect({to}: { to: string }) {
  useEffect(() => {
    window.location.replace(to)
  }, [])
  return null
}

function OneTap() {
  useGoogleOneTap()
  return <Fragment/>
}