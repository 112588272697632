import {configureStore} from '@reduxjs/toolkit'
import base from "store/reducers/base";

export const store = configureStore({
  reducer: {
    base
  },
})

// Infer the `RootState` and `AppDispatch` types from the index itself
export type ApplicationState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
