import {Dispatch, Ref, SetStateAction, useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {ApplicationState} from '../../store/store'
import {useLocation} from "react-router-dom";
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';


export type UseFormField<T> = {
  value: T
  error: string
  change: React.Dispatch<React.SetStateAction<T>>
  changeError: React.Dispatch<React.SetStateAction<string>>
}

type UseDropdown = {
  onClose?(): void,
  isOpen?: boolean
}

export function useDropdown({onClose, isOpen = false}: UseDropdown = {}): {
  open: boolean, toggle: any, ref: Ref<any>, setOpen: Dispatch<SetStateAction<boolean>>
} {
  const [open, setOpen] = useState(isOpen)
  const ref = useRef<HTMLDivElement>(null)

  const toggle = () => setOpen(!open)

  useEffect(() => {
    const mousedownListener = (e: MouseEvent) => {
      if (ref.current && e.target && !ref.current.contains(e.target as Node)) {
        document.removeEventListener('mousedown', mousedownListener)
        setOpen(false)
      }
    }

    const keydownListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setOpen(false)
        e.preventDefault()
        e.stopPropagation()
      }
    }

    if (open) {
      document.addEventListener('mousedown', mousedownListener)
      document.addEventListener('keydown', keydownListener)
    } else {
      document.removeEventListener('mousedown', mousedownListener)
      document.removeEventListener('keydown', keydownListener)
      onClose?.()
    }

    return () => {
      document.removeEventListener('keydown', keydownListener)
      document.removeEventListener('mousedown', mousedownListener)
    }
  }, [open])

  return {open, toggle, ref, setOpen}
}

export function useStore<TSelected = unknown>(
  selector: (state: ApplicationState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
) {
  return useSelector(selector, equalityFn)
}


function useTimeout(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return
    }

    const id = setTimeout(() => savedCallback.current(), delay)

    return () => clearTimeout(id)
  }, [delay])
}

export default useTimeout

export function usePageView() {
  const {pathname} = useLocation() || {}
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
}

export function useOnClickOutside(ref: any, handler: any) {
  useEffect(
    () => {
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export function useAlert(initialValue = false) {
  const [isShow, setIsShow] = useState(initialValue)
  const show = () => setIsShow(true)
  const hide = () => setIsShow(false)

  return {
    isShow,
    show,
    hide
  }
}