import {ForwardedRef, forwardRef, memo, useState} from "react";
import UploadFile from "../UploadFile";
import s from './index.module.scss';
import classnames from "classnames/bind";
import {UploadFilePropsType} from "../UploadFile/index.props";
import {Maybe} from "apollo/types_schema";
import {toast} from "react-toastify";
import {Typography} from "../modules";
import {ColorEnum, TypographyVariant} from "../../helpers/types";
import {StarIcon} from "../../assets/Icons/starIcon";
import {useStore} from "helpers/hooks";

const cx = classnames.bind(s);
const UploadImage =
  forwardRef<ForwardedRef<HTMLInputElement>, Omit<UploadFilePropsType, 'setLoad' | 'loading' | 'onChangeUpload'> & { img: Maybe<string> }>
  (({
      className,
      children,
      img,
      onChange,
      label,
      ...uploadFileProps
    }, ref) => {
      const [loading, setLoading] = useState(false)
      const user = useStore(state => state.base.user)
      const onUpload = async (file: any) => {
        try {
          await onChange?.(file);
        } catch (e) {
          // @ts-ignore
          toast(e.message, {type: "error"})
        } finally {
          setLoading(false)
        }
      }

      return (
        <UploadFile
          label={label}
          loading={loading}
          setLoad={setLoading}
          ref={ref as any}
          className={cx('Component', className ?? '')}
          onChangeUpload={onUpload}
          {...uploadFileProps}

        >
          <div className={cx('Img')}>
            {user?.isActiveSubscription && <StarIcon className={cx('StarIcon')}/>}
            {loading ? <LoadingIcon/> :
              img
                ? <img src={img} alt=""/>
                : <ImageIcon/>
            }
          </div>
          <div>
            <div className={cx('Actions')}>
              <Typography variant={TypographyVariant.p16} style={{textDecoration: 'underline', marginRight: 16}}
                          color={ColorEnum.green}>Upload</Typography>

              <Typography
                hidden={!img}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (onChange) {
                    onChange(null as any)
                  }
                }}
                variant={TypographyVariant.p16} style={{textDecoration: 'underline'}}
                color={ColorEnum.red}>Delete</Typography>

            </div>
            <Typography style={{marginTop: 5}} color={ColorEnum.gray} variant={TypographyVariant.p14}>
              Recommended: JPG, PNG, at least 1,000 pixels per side.
            </Typography>

          </div>
          {/*{loading && <span className={cx('Pen')}> <LoadingIcon/> </span>}*/}

        </UploadFile>


      )
    }
  )
export default memo(UploadImage)

function ImageIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.20078 11.9998H12.8008L9.80078 7.9998L7.40078 11.1998L5.60078 8.7998L3.20078 11.9998ZM0.800781 15.1998V0.799805H15.2008V15.1998H0.800781Z"
        fill="#9DA3AD"/>
    </svg>

  )
}

export function LoadingIcon({fill = `var(${ColorEnum.gray})`, width = 21, height = 20, className}: {
  fill?: string
  width?: string | number,
  height?: string | number,
  className?: string
}) {
  return <svg className={`${s.Loading} ${className || ''}`} width={width} height={height} viewBox="0 0 21 20"
              fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.8181 9.55259L20.8175 9.57133C20.7711 10.8253 19.7799 11.8025 18.6017 11.7533C17.4815 11.7071 16.5973 10.7392 16.5535 9.56622L16.5523 9.5479C16.5085 8.29288 17.4279 7.23791 18.6063 7.19104C19.7849 7.1446 20.7747 8.12394 20.8185 9.37917C20.8205 9.43563 20.8205 9.4972 20.8181 9.55259Z"/>
    <path
      d="M18.8534 15.7171L18.8438 15.7313C18.2102 16.6478 17.0008 16.8434 16.1402 16.1691C15.3258 15.5305 15.1218 14.3321 15.641 13.4299L15.65 13.4156C16.2114 12.4471 17.402 12.1472 18.311 12.7437C19.2202 13.3415 19.5024 14.6105 18.941 15.5788C18.9148 15.6246 18.8838 15.6736 18.8534 15.7171Z"/>
    <path
      d="M14.0842 19.5872L14.069 19.593C13.1692 19.9379 12.1768 19.4398 11.8532 18.4815C11.5466 17.5733 11.9566 16.5742 12.7648 16.179L12.7798 16.1711C13.6504 15.7493 14.6778 16.1577 15.0747 17.0857C15.4719 18.0133 15.0877 19.1074 14.2168 19.5308C14.1742 19.551 14.1274 19.5704 14.0842 19.5872Z"/>
    <path
      d="M8.21664 19.9512L8.20004 19.9468C7.38003 19.7292 6.88042 18.8436 7.08482 17.9706C7.27882 17.141 8.04363 16.6201 8.82224 16.7565L8.83884 16.7593C9.67325 16.9082 10.2365 17.7482 10.0975 18.637C9.95806 19.5249 9.16885 20.1257 8.33464 19.9772C8.29584 19.9706 8.25424 19.9608 8.21664 19.9512Z"/>
    <path
      d="M3.26027 16.8274L3.24927 16.814C2.75806 16.2326 2.80266 15.3374 3.34927 14.8133C3.86648 14.3178 4.65249 14.3386 5.15089 14.8361L5.16289 14.8482C5.6973 15.3851 5.7217 16.282 5.21749 16.8519C4.71329 17.4211 3.87128 17.4477 3.33647 16.9107C3.31067 16.8845 3.28367 16.8546 3.26027 16.8274Z"/>
    <path
      d="M0.833693 11.4023L0.831493 11.3842C0.744492 10.7267 1.1747 10.1192 1.79211 10.0265C2.37691 9.9385 2.92132 10.3505 3.05052 10.9526L3.05432 10.9694C3.19112 11.6179 2.80912 12.2611 2.20011 12.4081C1.5919 12.5538 0.987295 12.1464 0.850293 11.4982C0.843693 11.4668 0.837893 11.4332 0.833693 11.4023Z"/>
    <path
      d="M1.64883 5.57861L1.65663 5.56285C1.87963 5.07137 2.43484 4.86599 2.89604 5.10396C3.33365 5.32957 3.52785 5.87666 3.35645 6.3511L3.35045 6.3675C3.16444 6.87666 2.62704 7.1289 2.14903 6.93163C1.67103 6.73393 1.43382 6.16106 1.61923 5.6519C1.62823 5.6274 1.63863 5.60205 1.64883 5.57861Z"/>
    <path
      d="M5.29528 1.32931L5.30908 1.32079C5.65529 1.10498 6.10069 1.22939 6.3031 1.59838C6.4951 1.94776 6.3983 2.39344 6.09289 2.62267L6.07949 2.63247C5.75089 2.87811 5.29708 2.79332 5.06688 2.44287C4.83628 2.09263 4.91608 1.60924 5.24488 1.36382C5.26088 1.3521 5.27848 1.33996 5.29528 1.32931Z"/>
    <path
      d="M10.4365 0.00106517L10.4537 0.0021304C10.7437 0.0204518 10.9643 0.286112 10.9471 0.594594C10.9305 0.88795 10.7019 1.11526 10.4305 1.1208L10.4133 1.12102C10.1227 1.12613 9.88329 0.879215 9.87849 0.569881C9.87369 0.260122 10.1055 0.00511296 10.3961 0C10.4093 0.00021304 10.4235 0.000426053 10.4365 0.00106517Z"/>
    <path
      d="M15.2905 1.91283L15.3031 1.92327C15.4455 2.0398 15.4721 2.25689 15.3627 2.40836C15.2589 2.55173 15.0697 2.58412 14.9285 2.48804L14.9157 2.47845C14.7643 2.37427 14.7211 2.15995 14.8189 1.99911C14.9165 1.83848 15.1181 1.79225 15.2689 1.89685C15.2755 1.90154 15.2833 1.9075 15.2905 1.91283Z"/>
    <path
      d="M18.2458 6.28638L18.2508 6.303C18.272 6.37181 18.2372 6.44659 18.173 6.4696C18.1116 6.49175 18.0446 6.45873 18.0198 6.39652L18.0136 6.38118C17.9864 6.31386 18.0156 6.23632 18.0788 6.20713C18.1414 6.17816 18.2148 6.20926 18.2414 6.27658C18.2434 6.27978 18.2446 6.28297 18.2458 6.28638Z"/>
  </svg>

}