import s from './index.module.scss'
import {useMedia} from "helpers/hooks/useMedia";

export const AdaptiveHelper = () => {
  const {laptop, ipad, tablet, landscape, phone} = useMedia()
  return (
    <div className={s.Component}>
      <p>
        {
          phone ? 'phone' : landscape ? 'landscape' : tablet ? 'tablet' : ipad ? 'ipad' : laptop ? 'laptop' : 'desktop'
        }
      </p>
    </div>
  )
}
