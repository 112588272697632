import {FC, Fragment} from "react";
import {GroupTagType, TagType} from "apollo/types_schema";
import {TagItem, Typography} from "components/modules";
import s from './index.module.scss';
import classNames from "classnames/bind";
import {useMedia} from "helpers/hooks/useMedia";
import {CloseIcon} from "assets/Icons/close";
import {FontWeight, NavbarTagListType, TypographyVariant} from "helpers/types";

const cx = classNames.bind(s);

type FeaturesTagsPropsType = {
  activeTag: TagType,
  setActiveTag: (tag: TagType | null) => void,
  tagsGroupsList?: NavbarTagListType,
  isOpenTagsNavbar: boolean,
  closeTagsNavbar: () => void,
}
export const FeaturesTags: FC<FeaturesTagsPropsType> =
  ({
     tagsGroupsList,
     isOpenTagsNavbar = false,
     activeTag,
     setActiveTag,
     closeTagsNavbar,
   }) => {
    const {landscape} = useMedia()
    const handleTagClick = (tagId: TagType['id'], tag: TagType) => {
      setActiveTag(tag as TagType)
      isOpenTagsNavbar && closeTagsNavbar()
    }
    return (
      <aside className={cx("Filters", {FiltersMobile: landscape, FiltersMobileOpen: landscape && isOpenTagsNavbar})}>
        {landscape && (
          <>
            <button className={cx('CloseNavbarTags')} onClick={closeTagsNavbar}><CloseIcon/></button>
            <Typography variant={TypographyVariant.h20} weight={FontWeight.SemiBold}>Tags</Typography>
          </>
        )}

        {/*{tagsGroupsList && Object.keys(tagsGroupsList)*/}
        {/*  ?.sort((a, b) => tagsGroupsList?.[b]?.length - tagsGroupsList?.[a]?.length)*/}
        {/*  ?.map((groupName) => (*/}
        {/*    <Fragment key={groupName}>*/}
        {/*      <h3>{groupName}</h3>*/}
        {/*      <div>*/}
        {/*        {tagsGroupsList[groupName].map((tag) => {*/}
        {/*          return (*/}
        {/*            <TagItem*/}
        {/*              key={tag?.id ?? '' + tag?.name}*/}
        {/*              onClick={handleTagClick}*/}
        {/*              tag={tag as TagType}*/}
        {/*              classNameComponent={cx('Button', {ActiveButton: tag?.id === activeTag?.id})}*/}
        {/*            />*/}
        {/*          )*/}
        {/*        })}*/}
        {/*      </div>*/}
        {/*    </Fragment>*/}
        {/*  ))}*/}

        <h3>Industries</h3>
        <div>
          {tagsGroupsList?.['Industries']?.map(tag => (
            <TagItem
              key={tag?.id ?? '' + tag?.name}
              onClick={handleTagClick}
              tag={tag as TagType}
              classNameComponent={cx('Button', {ActiveButton: tag?.id === activeTag?.id})}
            />
          ))}
        </div>
        <h3>Tasks</h3>
        <div>
          {tagsGroupsList?.['Tasks']?.map(tag => (
            <TagItem
              key={tag?.id ?? '' + tag?.name}
              onClick={handleTagClick}
              tag={tag as TagType}
              classNameComponent={cx('Button', {ActiveButton: tag?.id === activeTag?.id})}
            />
          ))}
        </div>
        <h3>Sources</h3>
        <div>
          {tagsGroupsList?.['Sources']?.map(tag => (
            <TagItem
              key={tag?.id ?? '' + tag?.name}
              onClick={handleTagClick}
              tag={tag as TagType}
              classNameComponent={cx('Button', {ActiveButton: tag?.id === activeTag?.id})}
            />
          ))}
        </div>
        {tagsGroupsList && Object.keys(tagsGroupsList).filter(groupName => groupName !== 'Sources' && groupName !== 'Tasks' && groupName !== 'Industries')
          ?.sort((a, b) => tagsGroupsList?.[b]?.length - tagsGroupsList?.[a]?.length)
          ?.map((groupName) => (
            <Fragment key={groupName}>
              <h3>{groupName}</h3>
              <div>
                {tagsGroupsList[groupName].map((tag) => {
                  return (
                    <TagItem
                      key={tag?.id ?? '' + tag?.name}
                      onClick={handleTagClick}
                      tag={tag as TagType}
                      classNameComponent={cx('Button', {ActiveButton: tag?.id === activeTag?.id})}
                    />
                  )
                })}
              </div>
            </Fragment>
          ))}
      </aside>
    )
  }
