import {CSSProperties, FC} from "react";
import {ColorEnum, Orientation} from "helpers/types";

type ArrowIconPropType = {
  orientation?: Orientation,
  width?: CSSProperties['width'],
  height?: CSSProperties['height']
  strokeWidth?: CSSProperties['strokeWidth'],
  stroke?: ColorEnum,
  className?: string

}
export const ArrowIcon: FC<ArrowIconPropType> =
  ({
     orientation = Orientation.Right,
     strokeWidth = '1.5',
     width = '8',
     height = '14',
     stroke = ColorEnum.dark_gray,
     className = ''
   }) => {
    const style: CSSProperties = {
      transform: orientation === Orientation.Left ? 'rotate(0deg)' : orientation === Orientation.Top ? 'rotate(90deg)' : orientation === Orientation.Right ? 'rotate(180deg)' : 'rotate(-90deg)',
      transition: 'transform .25s ease-in-out'

    }
    return (
      <svg className={className} style={style} width={width} height={height} viewBox="0 0 8 14" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M7.0835 12.4166L1.25016 6.99992L7.0835 1.58325"
              stroke={`var(${stroke})`} strokeWidth={strokeWidth}
              strokeLinecap="round" strokeLinejoin="round"/>
      </svg>

    )
  }
