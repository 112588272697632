import useMatchMedia from "use-match-media-hook";

const queries = [
  '(max-width: 1439px)',
  '(max-width: 1279px)',
  '(max-width: 1023px)',
  '(max-width: 767px)',
  '(max-width: 575px)',
  '(max-width: 360px)',
]

export function useMediaWithoutListener(): { laptop: boolean, ipad: boolean, tablet: boolean, landscape: boolean, phone: boolean, phoneMini: boolean } {
  return {
    laptop: window.innerWidth < 1439,
    ipad: window.innerWidth < 1279,
    tablet: window.innerWidth < 1023,
    landscape: window.innerWidth < 767,
    phone: window.innerWidth < 575,
    phoneMini: window.innerWidth < 360
  }

}

export function useMedia(): { laptop: boolean, ipad: boolean, tablet: boolean, landscape: boolean, phone: boolean, phoneMini: boolean } {
  const [laptop, ipad, tablet, landscape, phone, phoneMini] = useMatchMedia(queries) || [true, false, false, false, false, false]
  return {laptop, ipad, tablet, landscape, phone, phoneMini}
}