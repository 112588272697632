import classNames from "classnames/bind";
import React from "react";
import s from './index.module.scss';

const cx = classNames.bind(s);

export function Top({percent, className}: { percent: number , className?: string}) {
  return (
    <div className={cx('Component', className || '')}>
      Top {percent} %
    </div>
  )
}