import React, {FC, Fragment, memo} from "react";
import classNames from "classnames/bind";
import s from './index.module.scss';
import {FeatureType, QueryGetFeaturesArgs, TagType} from "apollo/types_schema";
import {Button, TagItem, Tooltip} from "components/modules";
import {FavoriteIcon} from "assets/Icons/favoriteIcon";
import {StarIcon} from "assets/Icons/starIcon";
import {useSaveFeatureWithCache} from "apollo/cache/saveFeatureWithCache";
import {Link, useParams} from "react-router-dom";
import {useStore} from "helpers/hooks";
import {useModalSign} from "helpers/hooks/useModalSign";
import {useRemoveFeatureWithCache} from "apollo/cache/removeFeatureWithCache";
import {ModalFeatureEnum, ModalFeaturePayloadType} from "pages/Features";
import {LOCAL_STORAGE_isHideSave} from "modals/ModalFollow";
import {format, isSameYear} from 'date-fns'
import {RoutePath} from "helpers/routes";
import {generate_url} from "helpers/generateSlug";

const cx = classNames.bind(s);

export type FeatureCardPropsType = {
  feature: FeatureType,
  view: 'default' | 'large' | 'small',
  queryVariables?: QueryGetFeaturesArgs,
  onTagClick?: (tag: TagType) => void,
  className?: string,
  hideTextMemberOnly?: boolean,
  openModal?: (payload: ModalFeaturePayloadType) => void
}


const Action = memo((props: {
    openModal?: (payload: ModalFeaturePayloadType) => void,
    queryVariables?: QueryGetFeaturesArgs, featureId: FeatureType['id'], isSaved: boolean | undefined, subscribers: number | undefined, onSave?: (featureId: FeatureType['id']) => void,
  }) => {
    const {id} = useParams<{ id: string }>()
    const {openModalSign} = useModalSign()
    const isAuth = useStore(state => state.base.isAuth)
    const [saveFeatureMutation, {loading: loadingSave}] = useSaveFeatureWithCache({
      context: props.queryVariables
    })
    const [removeFeatureMutation, {loading: loadingRemove}] = useRemoveFeatureWithCache({
      context: props.queryVariables
    })
    const toggleFeature = async () => {
      if (isAuth && (id || props.featureId)) {
        if (!props.isSaved) {
          const result = await saveFeatureMutation({
            variables: {
              featureId: id || props.featureId
            }
          })
          if (result.data?.saveFeature?.ok && !window.localStorage.getItem(LOCAL_STORAGE_isHideSave)) {
            props?.openModal?.({view: ModalFeatureEnum.Save})
          }
        } else {
          await removeFeatureMutation({
            variables: {
              featureId: id || props.featureId
            }
          })
        }

      } else {
        openModalSign()
      }


    }

    return <div className={cx("Action")}>
      <button
        disabled={loadingSave || loadingRemove}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          toggleFeature()
        }}
        className={cx("BtnSave")}>
        <FavoriteIcon isFavorite={!!props.isSaved}/>
        <p>{props.isSaved ? 'saved' : 'save'}</p>
      </button>
      <span className={cx("Subscribers")}>{props.subscribers ?? 0}</span>
    </div>;
  }
)

 const FeatureCard: FC<FeatureCardPropsType> = memo(
  ({
     feature, view, queryVariables, onTagClick, className, openModal, hideTextMemberOnly = false

   }) => {

    const date = feature.created ? format(new Date(feature.created), isSameYear(new Date(feature.created), new Date()) ? 'dd MMM' : 'dd MMM YYY') : ''
    const {user} = useStore(state => state.base)
    if (!feature) return <Fragment/>
    return (
      <div className={cx("Component", className || '', {
        ComponentDefault: view === 'default',
        ComponentSmall: view === 'small',
        ComponentLarge: view === 'large'

      })}>
        <div className={cx('Header')}>
          <div className={cx('Info')}>
            <p className={cx("Date")}>{date}</p>
            {feature?.isOnlyMember &&

              <Tooltip content={user?.isActiveSubscription ? null : <MemberTooltip/>}>
                <p className={cx('Member', {MemberLarge: view === 'large'})}>
                  <StarIcon/> {hideTextMemberOnly ? '' : 'Member only'}</p>
              </Tooltip>}
            {/*{view === 'default' && <Top className={cx('Top')} percent={percent}/>}*/}
          </div>

          {view !== 'small' && (
            <Action
              openModal={openModal}
              queryVariables={queryVariables}
              featureId={feature?.id}
              isSaved={!!feature?.isSaved} subscribers={feature?.savedFeature ?? 0}/>)
          }
        </div>
        <Link to={'/feature/' + generate_url(feature?.title ?? '') + '/' + feature.id} className={cx('MainInfo')}>
          <p className={cx("Title", {
            TitleDefault: view === 'default',
            TitleLarge: view === 'large',
            TitleSmall: view === 'small'
          })}>
            <span>{feature?.title}</span>
          </p>
          {view !== 'large' && (
            <p className={cx("Description")}>{feature?.previewDescription}</p>
          )}
        </Link>
        {view !== 'small' && (feature?.tags?.length ?? 0) > 0 && (
          <div className={cx("Tags")}>
            {feature?.tags?.map((tag) =>
              <TagItem
                key={tag?.id}
                // classNameComponent={cx({TagSmall: view === 'small'})}
                onClick={(tagId, tag) => onTagClick?.((tag))}
                tag={tag as TagType}/>
            )}
          </div>
        )}
      </div>
    );
  }
)
export default FeatureCard
function MemberTooltip() {
  return (
    <div className={cx('MemberTooltip')}>
      <h5>Member-only </h5>
      <p>Become a FeatureHub.AI member to enjoy unlimited access to best features.</p>
      <Button tag={'link'} to={RoutePath.Membership} size='small'>Get unlimited access</Button>
    </div>
  )
}