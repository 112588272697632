import React, {CSSProperties, FC} from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import {Link, LinkProps} from 'react-router-dom'
import {ButtonColorEnum, ButtonPropsType} from './index.props'

const cx = classNames.bind(styles)

const Button: FC<ButtonPropsType>
  = ({
       size = 'small',
       color = ButtonColorEnum.Orange,
       isOutline,
       className,
       view = 'default',
       tag = 'button',
       style = {},
       ...rest
     }) => {
  const colorClassName = (color ? `Btn_${isOutline ? 'outline_' : ''}${color}` : "")
  const sizeClassName = (size ? `${size}` : '')
  const viewClassName = (view ? `${view}` : '')
  const props = {
    className: cx(
      'Btn',
      colorClassName,
      sizeClassName,
      viewClassName,
      className
    ),
    style: {
      ...style
    } as CSSProperties
  }


  if (tag === 'a') {
    return <a  {...props} {...(rest as ButtonPropsAnchor)} />
  } else if (tag === 'link') {
    return <Link {...props} {...(rest as ButtonPropsLink)} />
  } else {
    return <button {...props} {...(rest as ButtonProps)} />
  }
}

export default Button

export type ButtonProps = {
  /** @default button*/
  tag?: 'button'
} & React.ButtonHTMLAttributes<HTMLButtonElement>
export type ButtonPropsLink = { tag: 'link' } & LinkProps
export type ButtonPropsAnchor = { tag: 'a' } & React.AnchorHTMLAttributes<HTMLAnchorElement>

