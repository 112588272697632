import s from './index.module.scss';
import classNames from "classnames/bind";
import {FC, Fragment} from 'react';

const cx = classNames.bind(s);


export type AlertPropsType = {
  variant?: 'error' | 'warning' | 'success' | 'info',
  onClose?: () => void,
  title: any,
  children?: any,
  isShow: boolean,
  classNameComponent?: string
}

export const Alert: FC<AlertPropsType> = (
  {
    onClose,
    variant = 'warning',
    title,
    children,
    isShow,
    classNameComponent
  }) => {
  if (!isShow) return <Fragment/>
  return (
    <div
      className={cx('Component', {
        Warning: variant === 'warning',
        Error: variant === 'error',
        Success: variant === 'success',
        Info: variant === 'info',
        Padding: !!onClose,
      }, classNameComponent || '')}
    >
      {onClose && (
        <button
          className={cx('CloseBtn')}
          onClick={onClose}>
          <CloseIcon/>
        </button>
      )}
      <div>
        <h5 className={cx('Title', {TitlePadding: !!children, TitlePaddingRight: !!onClose})}>{title}</h5>
        {children}
      </div>
    </div>
  )
}

function CloseIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.88867 8L15.627 14.748L14.748 15.627L8 8.88867L1.25195 15.627L0.373047 14.748L7.11133 8L0.373047 1.25195L1.25195 0.373047L8 7.11133L14.748 0.373047L15.627 1.25195L8.88867 8Z"
        fill="#212936"/>
    </svg>

  )
}