import React, {FC} from 'react';
import {useStore} from 'helpers/hooks';
import {useModalSign} from "helpers/hooks/useModalSign";
import {useNavigate} from 'react-router-dom';
import {RoutePath} from "../../helpers/routes";

type ControlButtonPropsType = { children: any, className?: string }
export const ControlButton: FC<ControlButtonPropsType> = ({children, className}) => {
  const {openModalSign} = useModalSign()
  const navigate = useNavigate()
  const {isAuth, user} = useStore(state => state.base)

  function handleClick() {
    if (!isAuth) {
      openModalSign()
    } else if (isAuth && !user?.isActiveSubscription) {
      navigate(RoutePath.Membership)
    } else {
      navigate(RoutePath.Features);
    }
  }

  return (
    <span style={{cursor: 'pointer!important'}} className={className || ''} onClick={handleClick}>
      {children}
    </span>
  );
};

