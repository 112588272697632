import React, {Fragment, lazy, useEffect, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import s from "./index.module.scss";
import classNames from "classnames/bind";
import {
  FeatureType,
  MessageLocationEnum,
  QueryGetFeaturesArgs,
  TagType,
  useGetFeatureFollowingQuery,
  useGetFeatureSavedQuery,
  useGetFeaturesQuery,
  useGetMessageQuery,
  useGetNavbarTagsQuery,
} from "apollo/types_schema";
import {Divider, FeatureCard, Loading, Typography} from "components/modules";
import {FeaturesTags} from "./FeaturesTags";
import {useStore} from "helpers/hooks";
import {FeaturesHeader} from "./FeaturesHeader";
import {useMedia} from "helpers/hooks/useMedia";
import {FontWeight, NavbarTagListType, TypographyVariant} from "helpers/types";
import {SavingFeaturesEmptyIcon} from "assets/Icons/savingFeaturesEmpty";
import {CatIcon} from "components/UI/NotFount";
import {FollowingFeaturesEmptyIcon} from "assets/Icons/followingFeaturesEmpty";
import {useModal} from "helpers/hooks/useModal";
import {StateRedirectMembership} from "../Membership";
import {useModalSign} from "../../helpers/hooks/useModalSign";
import {useDispatch} from "react-redux";
import {editBase} from "store/reducers/base";

const ModalFollow = lazy(() => import('modals/ModalFollow'))

const cx = classNames.bind(s);
const initialFiltersList = ["Latest", "Saved", "Following"];

const initialQueryVariables = {groups: null, tags: null, title: null} as QueryGetFeaturesArgs

export enum ModalFeatureEnum {
  Follow,
  Save
}

export type ModalFeaturePayloadType = { view: ModalFeatureEnum, tagName?: string }
const Features = () => {
  const {
    isOpen,
    close,
    payload,
    open
  } = useModal<ModalFeaturePayloadType>(false, {view: ModalFeatureEnum.Follow})
  const {openModalSign} = useModalSign()
  const [search] = useSearchParams()
  const {state} = useLocation()
  const {user, isAuth, paymentSuccess} = useStore(state => state.base)
  const urlTags = search?.get('tags')
  const {landscape} = useMedia()
  const [filtersList, setFiltersList] = useState<Array<string>>(initialFiltersList)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isOpenTagsNavbar, setIsOpenTagsNavbar] = useState<boolean>(false)
  const closeTagsNavbar = () => setIsOpenTagsNavbar(false)
  const openTagsNavbar = () => setIsOpenTagsNavbar(true)
  const [queryVariables, setQueryVariables] = useState<QueryGetFeaturesArgs>(urlTags ? ({
    ...initialQueryVariables,
    tags: [urlTags]
  }) : initialQueryVariables)
  const [activeTab, setActiveTab] = useState(initialFiltersList[0]);
  const [tagsGroupsList, setTagsGroupsList] = useState<NavbarTagListType>();
  const [activeTag, setActiveTag] = useState<TagType | null>(null);
  const {loading: loadingFeatures, data, refetch: refetchFeatures} = useGetFeaturesQuery({
    variables: queryVariables,
    onCompleted(data) {
      Array.isArray(user?.tags) && setFiltersList(initialFiltersList.concat(user?.tags?.map(g => g?.name ?? '') as string[]))
    },
  });
  const {loading: loadingFeaturesFollowing, data: dataFollowing} = useGetFeatureFollowingQuery({
    skip: !isAuth
  })
  const {loading: loadingFeatureSaved, data: dataSaved} = useGetFeatureSavedQuery({
    skip: !isAuth
  })
  const {data: dataCongrats} = useGetMessageQuery({
    skip: !paymentSuccess,
    variables: {
      location: MessageLocationEnum.UserPaidCongrats
    }
  })
  useEffect(() => {
    refetchFeatures(queryVariables)
  }, [queryVariables])
  useEffect(() => {
    if ((state as StateRedirectMembership)?.isShowSign && !isAuth) {
      openModalSign()
    }
  }, [state])
  useEffect(() => {
    if (paymentSuccess) {
      document.onclick = () => {
        dispatch(editBase({field: 'paymentSuccess', value: false}))
      }
    } else {
      document.onclick = null
    }
  }, [paymentSuccess])
  const {loading: loadingTags} = useGetNavbarTagsQuery({
    onCompleted(data) {
      const navbarTags = (data.getNavbarTags as TagType[])?.reduce((acc, cur) => {
        if ((cur as any).group.name in acc) {
          return {
            ...acc,
            [(cur as any).group.name]: [...((acc as any)[(cur as any).group.name]), cur]
          }

        } else {
          return {
            ...acc,
            [(cur as any).group.name]: [cur]
          }
        }
      }, {}) as NavbarTagListType
      setTagsGroupsList(navbarTags);
      if (urlTags) {
        const tag = data?.getNavbarTags?.find((t) => t?.id === urlTags) || user?.tags?.find(t => t?.id === urlTags) as TagType
        setActiveTag(tag);
      }

    },
  });
  const getFeatures = () => {
    if (activeTab === 'Saved') {
      debugger
      return (dataSaved?.getFeatureSaved ?? []) as FeatureType[]
    } else if (activeTab === 'Following') {
      return (dataFollowing?.getFeatureFollowing ?? []) as FeatureType[]
    } else {
      return (data?.getFeatures ?? []) as FeatureType[]
    }
  }
  const removeUrlTag = () => urlTags && search.has('tags') && navigate({search: ''})
  const handleTabClick = (tab: string) => {
    if (tab === activeTab) return;
    removeUrlTag()
    setActiveTab(tab)
    if (tab === 'Following' || tab === 'Saved') {
      return;
    } else if (initialFiltersList.includes(tab)) {
      setQueryVariables({...queryVariables, tags: null})
    } else {
      setQueryVariables({...queryVariables, tags: [user?.tags?.find(g => g?.name === tab)?.id ?? null]})
    }

  }
  const handleTagClick = (tag: TagType | null) => {
    removeUrlTag()
    activeTag?.id === tag?.id ? setActiveTag(null) : setActiveTag(tag)
    const isSame = activeTag?.id === tag?.id
    if (isSame) {
      setQueryVariables(initialQueryVariables)
      setActiveTab(initialFiltersList[0])
      return;
    }
    setQueryVariables({...queryVariables, tags: tag ? [tag?.id] : null})
  }


  if (loadingFeatures && loadingTags && loadingFeaturesFollowing && loadingFeatureSaved) return <Loading/>
  return (
    <>
      <div className={cx("Component")}>
        <FeaturesTags
          setActiveTag={handleTagClick}
          activeTag={activeTag as TagType}
          tagsGroupsList={tagsGroupsList}
          closeTagsNavbar={closeTagsNavbar}
          isOpenTagsNavbar={isOpenTagsNavbar}
        />
        <main className={cx("FeaturesContainer")}>
          {paymentSuccess ? (
            <div className={cx('Congrats')}>
              <Typography variant={TypographyVariant.h32} weight={FontWeight.Bold}
                          className={cx('CongratsTitle')}>{dataCongrats?.getMessage?.text}</Typography>
              <Typography variant={TypographyVariant.p18}
                          dangerouslySetInnerHTML={{__html: dataCongrats?.getMessage?.btnText ?? ''}}
                          className={cx('CongratsText')}/>
            </div>) : null}
          <FeaturesHeader
            handleTagClick={handleTagClick}
            loading={loadingFeatures || loadingFeatureSaved || loadingFeaturesFollowing || loadingTags}
            activeTag={activeTag}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            filtersList={filtersList}
            openModal={open}
          />
          {landscape && <ButtonNavbar openTagsNavbar={openTagsNavbar}/>}
          {(loadingFeatures || loadingFeatureSaved || loadingFeaturesFollowing)
            ? <Loading isSticky={false} style={{marginTop: 200}}/>
            : <article className={cx('Features')}>
              {getFeatures()?.length > 0
                ? getFeatures().map(f => (
                  <Fragment key={f?.id}>
                    <FeatureCard
                      openModal={open}
                      queryVariables={queryVariables}
                      feature={f}
                      view={'default'}
                      onTagClick={handleTagClick}
                    />
                    <Divider/>
                  </Fragment>
                ))
                : <Gag
                  activeTag={activeTag}
                  activeTab={activeTab}/>}
            </article>
          }

        </main>
      </div>
      <ModalFollow
        payload={payload as ModalFeaturePayloadType}
        isOpen={isOpen}
        close={close}
      />
    </>
  );
};

function Gag({activeTab, activeTag}: { activeTab: string, activeTag: TagType | null }) {
  if (activeTab === 'Saved') {
    return (
      <GagLayout
        icon={<SavingFeaturesEmptyIcon/>}
        text={'Start saving features from around the Hub to view it here'}/>
    )
  }
  if (activeTab === 'Following') {
    return (
      <GagLayout
        text={'Follow topics that are important to you and see related features here'}
        icon={<FollowingFeaturesEmptyIcon/>}
      />)
  }
  if (activeTab === 'Latest' && activeTag) {
    return (
      <GagLayout
        text={<>
          <p>
            The features for this tag are currently in development</p>
          <p>Follow the tag to stay updated when they are released.</p>
        </>}
        icon={<CatIcon/>}/>
    )
  }
  return (
    <GagLayout
      text={`List features is empty`}
      icon={<CatIcon/>}/>
  )
}

function GagLayout({icon, text}: { icon: any, text: any }) {
  return (
    <div className={cx('Gag')}>
      <Typography className={cx('GagTitle')} variant={TypographyVariant.h20}>{text}</Typography>
      {icon}
    </div>
  )
}

function ButtonNavbar({openTagsNavbar}: { openTagsNavbar: any }) {
  return (
    <>

      <button className={cx('ButtonNavbar')} onClick={openTagsNavbar}>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.6545 5.58833L6.40453 0.338333C6.19453 0.128333 5.90286 0 5.58203 0H1.4987C0.851198 0 0.332031 0.519167 0.332031 1.16667V5.25C0.332031 5.57083 0.460365 5.8625 0.676198 6.0725L5.92036 11.3225C6.1362 11.5325 6.42787 11.6667 6.7487 11.6667C7.06953 11.6667 7.3612 11.5325 7.5712 11.3225L7.8162 11.0833C5.93203 11.025 4.41536 9.485 4.41536 7.58333C4.41536 5.6525 5.98453 4.08333 7.91537 4.08333C9.81703 4.08333 11.357 5.6 11.4154 7.48417L11.6545 7.23917C11.8704 7.02917 11.9987 6.7375 11.9987 6.41667C11.9987 6.09 11.8645 5.79833 11.6545 5.58833ZM2.3737 2.91667C1.88953 2.91667 1.4987 2.52583 1.4987 2.04167C1.4987 1.5575 1.88953 1.16667 2.3737 1.16667C2.85786 1.16667 3.2487 1.5575 3.2487 2.04167C3.2487 2.52583 2.85786 2.91667 2.3737 2.91667ZM7.97953 5.0225C9.43787 5.0225 10.6045 6.18917 10.6045 7.6475C10.6045 8.16667 10.4587 8.645 10.202 9.04167L11.9987 10.8558L11.1879 11.6667L9.3737 9.87583C8.96537 10.1267 8.4987 10.2725 7.97953 10.2725C6.5212 10.2725 5.35453 9.10583 5.35453 7.6475C5.35453 6.18917 6.5212 5.0225 7.97953 5.0225ZM7.97953 6.18917C7.6911 6.18917 7.40915 6.2747 7.16932 6.43494C6.9295 6.59518 6.74259 6.82295 6.63221 7.08942C6.52183 7.3559 6.49295 7.64912 6.54922 7.93201C6.60549 8.2149 6.74438 8.47475 6.94833 8.6787C7.15229 8.88265 7.41214 9.02154 7.69502 9.07781C7.97791 9.13408 8.27114 9.1052 8.53761 8.99482C8.80409 8.88445 9.03185 8.69753 9.19209 8.45771C9.35234 8.21789 9.43787 7.93593 9.43787 7.6475C9.43787 6.83667 8.79037 6.18917 7.97953 6.18917Z"
            fill="#31373C"/>
        </svg>
        <Typography variant={TypographyVariant.p12}>Search by tag</Typography>
      </button>
      <Divider/>
    </>
  )
}

export default Features;
