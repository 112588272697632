import {ForwardedRef, forwardRef} from "react"
import {UploadFilePropsType} from "./index.props"
import {compress, toBase64} from "helpers/fileHelperl";
import {parseChildren} from "helpers/parseChildren";


const maxSizeImage = 2;

const UploadFile =
  forwardRef<ForwardedRef<HTMLInputElement>, UploadFilePropsType>
  (({
      className,
      label,
      onChange,
      sizeCompress,
      children,
      setLoad,
      loading,
      onChangeUpload,
      ...inputProps
    }, ref) => {

    const onUpload = async (e: React.ChangeEvent<HTMLInputElement>): Promise<any> => {
      setLoad?.(true);
      if (e.target?.files?.[0]) {
        let file = e.target.files[0]
        let name = e.target.files[0].name
        let size = file.size / 1024 / 1024
        if (size > maxSizeImage || sizeCompress) {
          const compressOptions = {} as any
          if (sizeCompress) compressOptions.maxSizeMB = sizeCompress
          file = (await compress(file, compressOptions) || null) as any
        }
        const result = await toBase64(file)
        onChangeUpload?.(result, name);
      } else {
        setLoad?.(false)
      }
    }
    const onDrop = async (e: React.DragEvent<HTMLLabelElement>) => {
      e?.preventDefault()
      setLoad?.(true);
      if (e.dataTransfer?.files?.[0]) {
        let file = e.dataTransfer.files[0]
        let name = e.dataTransfer.files[0].name
        let size = file.size / 1024 / 1024
        if (size > maxSizeImage || sizeCompress) {
          const compressOptions = {} as any
          if (sizeCompress) compressOptions.maxSizeMB = sizeCompress
          file = (await compress(file, compressOptions) || null) as any
        }
        const result = await toBase64(file)
        onChangeUpload?.(result, name);
      } else {
        setLoad?.(false)
      }
    }
    return (
      <>
        <label
          onDrop={onDrop}
          onDragOver={e => e.preventDefault()}
          className={className ?? ''}
        >
          {parseChildren(children)}
          <input
            hidden
            onChange={onUpload}
            ref={ref as any}
            disabled={!!loading}
            type='file'
            {...inputProps}
          />
        </label>
      </>

    )
  })
export default UploadFile