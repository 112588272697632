import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Maybe, UserType} from "apollo/types_schema";
import {IS_AUTH} from "../../helpers/sign";


export enum ModalTypeEnum {
  SignUp,
  SingIn,
  SignUpEmail,
  SignInEmail,
  Forgot,
  Reset,
  UserEdit
}

export type InitialStateType = {
  isOpenModalSign: boolean,
  typeModalSign: ModalTypeEnum,
  isAuth: boolean,
  user: Maybe<UserType>,
  forgotPasswordUserId: UserType['id'] | null,
  paymentSuccess: boolean
}
type keys = keyof InitialStateType


const initialState: InitialStateType = {
  isOpenModalSign: false,
  typeModalSign: ModalTypeEnum.SignUp,
  isAuth: window.localStorage.getItem('IS_AUTH') ? JSON.parse(window.localStorage.getItem('IS_AUTH') as any) : false,
  user: null,
  forgotPasswordUserId: null,
  paymentSuccess: false
}

export const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    editBase(state, action: PayloadAction<{ field: keys, value: any } | { field: keys, value: any }[]>) {
      // @ts-ignore
      if (Array.isArray(action.payload)) {
        action.payload.forEach(({field, value}) => {
          // @ts-ignore
          state[field] = value
        })
      } else {
        // @ts-ignore
        state[action.payload.field] = action.payload.value
      }

    },
    editUser(state, action: PayloadAction<{ field: keyof UserType, value: any }>) {
      // @ts-ignore
      state.user[action.payload.field] = action.payload.value
    }
  }
})

// Action creators are generated for each case reducer function
export const {editBase, editUser} = baseSlice.actions

export default baseSlice.reducer
