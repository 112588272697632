import React, {FC, useRef, useState} from 'react';
import s from './index.module.scss';
import classNames from 'classnames/bind'

const cx = classNames.bind(s)

type PropsType = {}

export const Tooltip = ({children, content}: { children: any, content: any }) => {
  const [isShow, setShow] = useState(false)
  const tooltipRef = useRef<HTMLDivElement | null>(null)
  const open = () => setShow(true)
  const close = () => setShow(false)
  if (!content) return children
  return (
    <div
      className={cx('Component')}
      onMouseEnter={open}
      onMouseLeave={close}>
      <span
      >{children}</span>
      {isShow && <div className={cx('TooltipContainer')}>
        <div
          ref={tooltipRef}
          className={cx('Tooltip')}>{content}</div>
      </div>}
    </div>
  )
}