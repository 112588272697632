import {TagType} from "apollo/types_schema";
import React, {memo} from "react";
import s from './index.module.scss';
import classNames from "classnames/bind";

const cx = classNames.bind(s);

enum TagViewEnum {
  Industries = 'Industries',
  Tasks = 'Tasks',
  Sources = 'Sources'

}

export type TagItemPropsType = {
  classNameComponent?: string,
  tag: TagType,
  onClick?: (id: TagType['id'], tag: TagType) => void
}

export const TagItem = memo(({tag, classNameComponent, onClick}: TagItemPropsType) => {
    return (
      <button
        onClick={() => onClick?.(tag?.id, tag)}
        className={cx("Tag", {
          WithIcon: tag?.image,
          Industries: tag.group?.name === TagViewEnum.Industries,
          Tasks: tag?.group?.name === TagViewEnum.Tasks,
          Sources: tag?.group?.name === TagViewEnum.Sources,

        }, classNameComponent || '')}>
        {!!tag?.image && <div className={cx("TagImage")}>
          <img width={14} height={14} src={tag?.image ?? ""} alt=""/></div>
        }
        <span className={cx("TagName")}>{tag?.name}</span>
      </button>
    );
  }
)