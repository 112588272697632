import {ColorEnum, FontWeight, TypographyVariant} from 'helpers/types'

export interface TypographyPropsType {
  variant?: TypographyVariant,
  color?: ColorEnum,
  weight?: FontWeight,
}


export const variantsMapping = {
  h64: 'h1',
  h48: 'h2',
  h40: 'h3',
  h32: 'h4',
  h24: 'h5',
  h28: 'h5',
  h20: 'p',
  p18: 'p',
  p16: 'p',
  p14: 'p',
  p12: 'p',
} as any

