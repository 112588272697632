import * as Apollo from "@apollo/client";
import {
  FeatureType,
  GetFeatureDocument, GetFeatureFollowingDocument, GetFeatureFollowingQuery,
  GetFeatureQuery,
  GetFeatureQueryVariables, GetFeatureSavedDocument, GetFeatureSavedQuery,
  GetFeaturesDocument,
  GetFeaturesQuery,
  RemoveFeatureMutation,
  RemoveFeatureMutationVariables,
  useRemoveFeatureMutation
} from "../types_schema";
import {toast} from "react-toastify";

export function useRemoveFeatureWithCache(baseOptions?: Apollo.MutationHookOptions<RemoveFeatureMutation, RemoveFeatureMutationVariables>) {
  return useRemoveFeatureMutation({
    ...baseOptions,
    update(cache, {data}, {variables, context}) {
      if (data?.removeFeature?.ok && !data?.removeFeature?.errors) {
        let cacheFeature = cache.readQuery<GetFeatureQuery>({
          query: GetFeatureDocument,
          variables: {featureId: variables?.featureId} as GetFeatureQueryVariables
        })
        let cacheFeatures = cache.readQuery<GetFeaturesQuery>({
          query: GetFeaturesDocument,
          variables: context
        })
        let cacheFeaturesSaved = cache.readQuery<GetFeatureSavedQuery>({
          query: GetFeatureSavedDocument
        })
        let cacheFeatureFollowing = cache.readQuery<GetFeatureFollowingQuery>({
          query: GetFeatureFollowingDocument
        })

        if (cacheFeature) {
          const newFeature = {
            ...cacheFeature,
            getFeature: {
              ...cacheFeature.getFeature,
              savedFeature: (cacheFeature.getFeature?.savedFeature ?? 0) - 1,
              isSaved: false
            }
          } as GetFeatureQuery
          cache.writeQuery<GetFeatureQuery>({
            query: GetFeatureDocument,
            data: newFeature
          })
        }
        if (cacheFeatures) {
          let newFeatures = [] as Array<FeatureType>;
          newFeatures = cacheFeatures?.getFeatures?.map(c => c?.id === variables?.featureId
            ? ({...c, isSaved: false, savedFeature: (c?.savedFeature ?? 0) - 1})
            : c) as Array<FeatureType>
          const newFeaturesQuery = {
            ...cacheFeatures, getFeatures: newFeatures
          } as GetFeaturesQuery


          cache.writeQuery<GetFeaturesQuery>({
            query: GetFeaturesDocument,
            data: newFeaturesQuery
          })
        }
        if (cacheFeaturesSaved) {
          let newFeatures = [] as Array<FeatureType>;
          newFeatures = cacheFeaturesSaved?.getFeatureSaved?.filter(c => c?.id !== variables?.featureId) as Array<FeatureType>
          const newFeaturesQuery = {
            getFeatureSaved: newFeatures
          } as GetFeatureSavedQuery
          cache.writeQuery<GetFeatureSavedQuery>({
            query: GetFeatureSavedDocument,
            data: newFeaturesQuery
          })
        }
        if (cacheFeatureFollowing) {
          let newFeatures = [] as Array<FeatureType>;
          newFeatures = cacheFeatureFollowing?.getFeatureFollowing?.map(c => c?.id === variables?.featureId
            ? ({...c, isSaved: false, savedFeature: (c?.savedFeature ?? 0) - 1})
            : c) as Array<FeatureType>
          const newFeaturesQuery = {
            ...cacheFeatures, getFeatureFollowing: newFeatures
          } as GetFeatureFollowingQuery


          cache.writeQuery<GetFeatureFollowingQuery>({
            query: GetFeatureFollowingDocument,
            data: newFeaturesQuery
          })
        }

      } else if (data?.removeFeature?.errors) {
        toast(data?.removeFeature?.errors ?? 'Oops.. something failed', {type: 'error'})
      }
    }

  })
}

