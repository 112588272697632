import React from "react";
import classNames from "classnames/bind";
import s from "./index.module.scss";
import {Fragment} from "react";

import logo from "assets/Logo.svg";
import facebook from "assets/Icons/facebook.svg";
import instagram from "assets/Icons/instagram.svg";
import linkedin from "assets/Icons/linkedin.svg";
import {Link, useLocation} from "react-router-dom";
import {RoutePath} from "../../helpers/routes";

const cx = classNames.bind(s);

const Footer = () => {
  const {pathname} = useLocation()
  const isHide = pathname.includes('feature/') || pathname.includes('/features')
  if (isHide) return <Fragment/>
  return (
    <div>
      <div className={cx("Footer")}>
        <Link to={RoutePath.Features} className={cx("Logo")}>
          <img src={logo} alt="Logo"/>
          <h2>FeatureHub.Ai</h2>
        </Link>
        <div className={cx("Container")}>
          <div className={cx("Container-main")}>
            <h2>FeatureHub.Ai</h2>
            <a href="/">Our Members</a>
            <a href="/">Platform</a>
            <a href="/">Pricing</a>
          </div>
          <div className={cx("Container-info")}>
            <h2>Legal information</h2>
            <a href="/">Privacy Policy</a>
            <a href="/">Terms & Conditions</a>
          </div>
          <div className={cx("Container-address")}>
            <h2>We are here!</h2>
            <div>
              <a href="/">
                <img src={linkedin} alt="Linkedin"/>
              </a>
              <a href="/">
                <img src={facebook} alt="Facebook"/>
              </a>
              <a href="/">
                <img src={instagram} alt="Instagram"/>
              </a>
            </div>
            <p className={cx("Contacts")}> 5 Parv. Alan Turing, 75013 Paris</p>
          </div>
        </div>
      </div>
      <div className={cx("Copyright")}>
        <div className={cx("General")}>
          <div className={cx("Line")}></div>
          <p>Copyright © 2022 FeatureHub.AI. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
