import React, {createContext, memo, PropsWithChildren, Suspense, useContext, useEffect, useRef, useState,} from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import FocusLock from 'react-focus-lock'
import {createPortal} from 'react-dom'
import {Loading} from 'components/modules'
import {parseChildren} from "helpers/parseChildren";
import {CloseIcon} from 'assets/Icons/close'

const cx = classNames.bind(styles)

export type ModalProps = PropsWithChildren<{
  isOpen: boolean
  close?: () => void
  closable?: boolean
  className?: string
  onClose?: () => void,
  classNameBackdrop?: string,
  isOutsideClosable?: boolean
}>

export const ANIM_DURATION_BG = 350
export const ANIM_DURATION_MODAL = 400

// Helper to control need we close modal on click overlay or modal directly
let shouldClose: null | boolean = null
const setShouldClose = (v: typeof shouldClose) => {
  shouldClose = v
}

export const ModalContext = createContext({
  close() {
  }
})
export const useModalContext = () => useContext(ModalContext)

const isOverflow = () => document.body.style.overflow === 'hidden'

const setOverflow = (show: boolean) => {
  document.body.style.overflow = show ? 'hidden' : ''
}

const Modal = memo(
  ({
     isOpen,
     close,
     onClose,
     closable = true,
     children,
     // size,
     className = '',
     classNameBackdrop,
     isOutsideClosable = true
   }: ModalProps) => {
    const [modalOpened, setModalOpened] = useState(false)
    const [appliedWithPortal, setAppliedWithPortal] = useState(false)
    const isFirstRenderRef = useRef(true)
    const closeTimeoutId = useRef<any>()
    const onExternalCloseRef = useRef<() => void>()
    const modalPortal = useRef(document.createElement('div')).current
    const showModal = modalOpened && isOpen
    const isClosing = (modalOpened || isOpen) && !showModal

    const closeModal = (
      onBeforeAnimation = () => setModalOpened(false),
      onEndAnimation = close,
      withCloseAnimation = modalOpened || isOpen
    ) => {
      // enable body's overflow
      setOverflow(false)
      // remove ekyboard event listener
      window.onkeydown = null
      onBeforeAnimation()
      if (withCloseAnimation) {
        closeTimeoutId.current = setTimeout(() => {
          setAppliedWithPortal(false)
          onExternalCloseRef.current = undefined
          onClose?.()
          onEndAnimation?.()
          modalPortal && modalPortal.remove()
        }, ANIM_DURATION_MODAL)
      }
    }

    useEffect(
      () => () => {
        if (modalPortal) modalPortal.remove()
        if (isOverflow()) setOverflow(false)
        clearInterval(closeTimeoutId.current)
      },
      []
    )

    useEffect(() => {
      if (isOpen) {
        modalPortal && document.body.appendChild(modalPortal)
        setAppliedWithPortal(true)

        onExternalCloseRef.current = () => {
          closeModal(
            close,
            () => {
              setModalOpened(false)
            },
            true
          )
          onExternalCloseRef.current = undefined
        }
      } else if (!isFirstRenderRef.current) {
        onExternalCloseRef.current?.()
      }

      if (isFirstRenderRef.current) {
        isFirstRenderRef.current = false
      }
    }, [isOpen])

    useEffect(() => {
      if (appliedWithPortal) {
        setModalOpened(true)
        setOverflow(true)

        window.onkeydown = (e: KeyboardEvent) => {
          if (e.key === 'Escape') closeModal()
        }
      }
    }, [appliedWithPortal])

    if (!isOpen && !modalOpened) return null

    const onClickOverlay = () => {
      if (!isOutsideClosable) return;
      if (shouldClose === null) {
        setShouldClose(true)
      }

      if (shouldClose) {
        closeModal()
      }
      setShouldClose(null)
    }

    return createPortal(
      <ModalContext.Provider value={{close: () => closeModal()}}>
        <FocusLock>
          <aside
            onClick={onClickOverlay}
            style={{
              transitionDuration: `${ANIM_DURATION_BG / 1000}s`,
              transitionDelay: isClosing
                ? `${(ANIM_DURATION_BG / 1000) * 0.8}s`
                : '',
            }}
            className={cx(
              'Component',
              modalOpened && isOpen && 'Component_show',
              classNameBackdrop && classNameBackdrop
            )}
          >
            <div
              onMouseDown={() => setShouldClose(false)}
              onMouseUp={() => setShouldClose(false)}
              onClick={() => setShouldClose(false)}
              style={{
                transitionDuration: `${ANIM_DURATION_MODAL / 1000}s`,
                transitionDelay: showModal
                  ? `${(ANIM_DURATION_BG / 1000) * 0.2}s`
                  : '',
              }}
              className={cx('Modal', className)}
            >

              <button
                hidden={!closable}
                type='button'
                className={cx('BtnClose')}
                onClick={() => closeModal()}
              >
                <CloseIcon/>
              </button>


              <main className={cx('Main')}>
                <Suspense fallback={<Loading/>}>{parseChildren(children)}</Suspense>
              </main>
            </div>
          </aside>
        </FocusLock>
      </ModalContext.Provider>,
      modalPortal
    )
  }
)

export default Modal