import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/root.scss";
import "./styles/index.scss";
import {AppProvider as App} from "./Root/AppProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);
